import { Alert, Box, Button, Heading, Input, Text, VStack } from "native-base";
import { useContext, useState } from "react";
import api from "../services/api";
import { Platform } from "react-native";
import Container from "../components/Container/Container";
import { StatusBar } from "expo-status-bar";
import ActionBar from "../components/Topbar/ActionBar";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";

export default function ForgotPasswordScreen({ navigation }) {
    const [email, setEmail] = useState(null);

    const [errors, setErrors] = useState(null);
    const [success, setSuccess] = useState(false);

    function sendResetEmail() {
        api.auth()
            .sendPasswordResetLink({ email: email })
            .then((res) => {
                setErrors(null);
                setSuccess(true);
            })
            .catch((err) => {
                setErrors(err.response?.data?.errors);
            });
    }

    return (
        <>
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <ActionBar padding={4} close closeOnPress={() => navigation.goBack()} />

                <Box height="100%" justifyContent={"center"}>
                    {Platform.OS == "ios" && <StatusBar style="light" animated={true} />}
                    <Container maxWidth={400}>
                        <VStack space={3} padding={8}>
                            <Heading size="xl">Forgot password</Heading>
                            <Text>Enter your Dealio account email. A reset link will be sent to this email.</Text>
                            {errors && "email" in errors && <Text color={"error.500"}>{errors.email}</Text>}
                            {success ? (
                                <Alert status="success">
                                    An email has been sent to {email} with a password reset link.
                                </Alert>
                            ) : (
                                <>
                                    <Input
                                        placeholder="Email"
                                        onChangeText={(text) => {
                                            setEmail(text);
                                        }}
                                        mb={4}
                                    ></Input>

                                    <Button onPress={() => sendResetEmail()}>Send reset email</Button>
                                </>
                            )}
                        </VStack>
                    </Container>
                </Box>
            </KeyboardAwareScrollView>
        </>
    );
}
