import { MaterialCommunityIcons } from "@expo/vector-icons";
import { Box, Skeleton, useTheme, useToken } from "native-base";
import React, { Component, useEffect, useState } from "react";
import { Image, Platform, Animated } from "react-native";
import { Easing, EasingNode } from "react-native-reanimated";

export default function ImageLoader(props) {
    const [opacity, setOpacity] = useState(new Animated.Value(0));

    function onLoad() {
        Animated.timing(opacity, {
            toValue: 1,
            duration: 300,
            useNativeDriver: true,
        }).start();
    }

    return (
        <>
            <Animated.Image
                {...props}
                onLoad={onLoad}
                style={[
                    {
                        opacity: opacity,
                    },
                    props.style,
                ]}
            />
        </>
    );
}
