import { Ionicons } from "@expo/vector-icons";
import { useLinkTo } from "@react-navigation/native";
import { Button, Icon, IconButton } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../context/AuthContext";

export default function SaveButton({ onPress, enabled, isSaved }) {
    const { auth, setAuth } = useContext(AuthContext);
    const [saved, setSaved] = useState(isSaved || false);
    const linkTo = useLinkTo();

    useEffect(() => {
        setSaved(isSaved);
    }, [isSaved]);

    return (
        <IconButton
            onPress={() => {
                if (auth) {
                    enabled && setSaved(!saved);
                    if (!saved) {
                        onPress(true);
                    } else {
                        onPress(false);
                    }
                } else {
                    linkTo("/login");
                }
            }}
            icon={
                saved ? (
                    <Icon as={Ionicons} name="heart" color={"red.400"} size={"sm"} />
                ) : (
                    <Icon
                        as={Ionicons}
                        name="heart-outline"
                        _light={{ color: "black" }}
                        _dark={{ color: "white" }}
                        size={"sm"}
                    />
                )
            }
        />
    );
}
