import { FormControl, Input, Select, WarningOutlineIcon } from "native-base";
import React from "react";

export default function SelectInput({
    label,
    options,
    placeholder,
    error,
    onValueChange,
    defaultValue,
    isRequired,
    noBorder,
}) {
    return (
        <>
            {label && <FormControl.Label>{label}</FormControl.Label>}
            <Select
                placeholder={placeholder}
                defaultValue={defaultValue}
                onValueChange={onValueChange}
                isRequired={isRequired}
            >
                {options ? options.map((option) => <Select.Item label={option.label} value={option.value} />) : <></>}
            </Select>
            {error && (
                <FormControl.ErrorMessage leftIcon={<WarningOutlineIcon size="xs" />}>{error}</FormControl.ErrorMessage>
            )}
        </>
    );
}
