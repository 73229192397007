import { Box, Button, Center, Heading, Text, VStack } from "native-base";
import React from "react";
import { Linking, Platform } from "react-native";
import Container from "../Container/Container";
import ImageLoader from "../Loader/ImageLoader";

export default function LocationSplash() {
    return (
        <Container>
            <VStack space={3} my={8} p={4} alignItems="center">
                {Platform.OS == "ios" ? (
                    <ImageLoader
                        source={require("./../../assets/images/ios-location-settings.jpg")}
                        style={{
                            width: 300,
                            height: 140,
                        }}
                    ></ImageLoader>
                ) : Platform.OS == "android" ? (
                    <ImageLoader
                        source={require("./../../assets/images/android-location-settings.png")}
                        style={{
                            width: 250,
                            height: 180,
                        }}
                    ></ImageLoader>
                ) : (
                    <></>
                )}

                <Heading textAlign={"center"} size="md" mt={4}>
                    Your location is not enabled.
                </Heading>
                <Text textAlign={"center"}>Dealio uses your location to show you deals nearby.</Text>

                {Platform.OS == "web" ? (
                    <Text textAlign={"center"}>
                        Please enable location access in your browser and refresh the page.
                    </Text>
                ) : (
                    <Text textAlign={"center"}>
                        Please enable location access in your device settings and restart the app.
                    </Text>
                )}

                {Platform.OS == "ios" && (
                    <Button onPress={() => Linking.openURL("app-settings:")}>Go to settings</Button>
                )}

                {Platform.OS == "android" && (
                    <Button onPress={() => startActivityAsync(ActivityAction.LOCATION_SOURCE_SETTINGS)}>
                        Go to settings
                    </Button>
                )}
            </VStack>
        </Container>
    );
}
