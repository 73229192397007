import moment from "moment";

const WEB_URL = "http://10.0.0.11:80";

export default {
    intToPrice(number) {
        return (
            `$` +
            parseFloat(number / 100)
                .toFixed(2)
                .replace(/\B(?=(\d{3})+(?!\d))/g, ",")
        );
    },
    rebaseImage(path) {
        return String(path).replace("http://localhost", WEB_URL);
    },
    percentageChange(original_amount, new_amount) {
        return Math.ceil(((original_amount - new_amount) / original_amount) * 100) + "%";
    },
    pad(n, width, z) {
        z = z || "0";
        n = n + "";
        return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
    },
    deg2rad(deg) {
        return deg * (Math.PI / 180);
    },
    getReadableDistanceFromLatLon(lat1, lon1, lat2, lon2) {
        var R = 6371; // Radius of the earth in km
        var dLat = this.deg2rad(lat2 - lat1); // deg2rad below
        var dLon = this.deg2rad(lon2 - lon1);
        var a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.deg2rad(lat1)) * Math.cos(this.deg2rad(lat2)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
        var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        var d = R * c; // Distance in km
        if (d < 1) {
            return (d * 100).toFixed(0) + "m";
        }
        return d.toFixed(1) + "km";
    },
    formatDate(date) {
        var months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        var day = date.getDate();
        var month = months[date.getMonth()];
        var year = date.getFullYear();
        var time = this.formatAMPM(date);
        return `${day} ${month} ${year} ${time}`;
    },
    formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours >= 12 ? "pm" : "am";
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        minutes = minutes < 10 ? "0" + minutes : minutes;
        var strTime = hours + ":" + minutes + " " + ampm;
        return strTime;
    },
    dateToDayOfWeek(date) {
        var days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        return days[date.getDay()];
    },
    readableTimeUntil(date) {
        var time = moment(date).fromNow();
        return time;
    },
    secondsUntil(date) {
        var b = moment();
        var a = moment(date);
        var time = a.diff(b); // Milliseconds

        return Math.floor(time / 1000);
    },
    formatDateToDateTime(date) {
        return (
            date.getFullYear() +
            "-" +
            this.pad((date.getMonth() + 1) % 12, 2) +
            "-" +
            this.pad(date.getDate(), 2) +
            " " +
            this.pad(date.getHours(), 2) +
            ":" +
            this.pad(date.getMinutes(), 2)
        );
    },
    formatDateTimeToDate(datetime) {
        if (datetime) {
            var bits = datetime.split(/\D/);
            return new Date(bits[0], --bits[1], bits[2], bits[3], bits[4]);
        } else {
            return null;
        }
    },
    formatDateToUTCDateTime(date) {
        return (
            date.getUTCFullYear() +
            "-" +
            this.pad((date.getUTCMonth() + 1) % 12, 2) +
            "-" +
            this.pad(date.getUTCDate(), 2) +
            " " +
            this.pad(date.getUTCHours(), 2) +
            ":" +
            this.pad(date.getUTCMinutes(), 2)
        );
    },
    convertUTCDateTimeToLocalDate(datetime) {
        if (datetime) {
            var bits = datetime.split(/\D/);
            var utc = new Date(Date.UTC(bits[0], --bits[1], bits[2], bits[3], bits[4]));
            return utc;
        } else {
            return null;
        }
    },
    convertUTCDateTimeToLocalDateTime(datetime) {
        var dateObj = this.convertUTCDateTimeToLocalDate(datetime);
        return this.formatDateToDateTime(dateObj);
    },
    convertUTCDateTimesToLocal(data, keys) {
        if (Array.isArray(data)) {
            var dataCopy = [...data];
        } else {
            var dataCopy = Object.assign({}, data);
        }

        keys.forEach((key) => {
            if (Array.isArray(dataCopy)) {
                dataCopy.forEach((item) => {
                    item[key] = this.convertUTCDateTimeToLocalDateTime(item[key]);
                });
            } else {
                dataCopy[key] = this.convertUTCDateTimeToLocalDateTime(dataCopy[key]);
            }
        });
        return dataCopy;
    },
    formatDateTimeRange(starting, ending, repeat) {
        const startingDate = this.formatDateTimeToDate(starting);
        const endingDate = this.formatDateTimeToDate(ending);

        if (repeat == "never") {
            return this.neverDatesFormat(startingDate, endingDate);
        }
        if (repeat == "weekly") {
            return this.weeklyDatesFormat(startingDate, endingDate);
        }
        if (repeat == "daily") {
            return `Every day ${this.formatAMPM(startingDate)} - ${this.formatAMPM(endingDate)}`;
        }
        if (repeat == "weekdays") {
            return `Weekdays ${this.formatAMPM(startingDate)} - ${this.formatAMPM(endingDate)}`;
        }
    },
    neverDatesFormat(starting, ending) {
        const startDay = starting.getFullYear() + "-" + starting.getMonth() + "-" + starting.getDate();
        const endDay = ending.getFullYear() + "-" + ending.getMonth() + "-" + ending.getDate();
        const startingFormatted = this.formatDate(starting);
        const endingFormatted = this.formatDate(ending);
        if (startDay == endDay) {
            return `${startingFormatted} - ${this.formatAMPM(ending)}`;
        } else {
            return `${startingFormatted} - ${endingFormatted}`;
        }
    },
    weeklyDatesFormat(starting, ending) {
        const startingDay = this.dateToDayOfWeek(starting);
        const startingTime = this.formatAMPM(starting);
        const endingDay = this.dateToDayOfWeek(ending);
        const endingTime = this.formatAMPM(ending);
        if (startingDay == endingDay) {
            return `${startingDay}s ${startingTime} - ${endingTime}`;
        } else {
            return `${startingDay} ${startingTime} - ${endingDay} ${endingTime}`;
        }
    },
    getCountDown(starting, ending, repeat) {
        const now = new Date();
        const startingDate = this.formatDateTimeToDate(starting);
        const endingDate = this.formatDateTimeToDate(ending);

        var seconds = 0;
        var ended = false;

        const { start, end } = this.getActiveOrNextDates(startingDate, endingDate, repeat);

        if (start && end) {
            if (start < now && now < end) {
                seconds = this.secondsUntil(end);
            }
            if (start > now) {
                seconds = this.secondsUntil(start);
            }
            if (repeat == "never" && end < now) {
                ended = true;
            }
            return {
                seconds: seconds,
                isActive: this.dealIsActive(start, end),
                ended: ended,
            };
        }
        return {
            seconds: 0,
            isActive: false,
            ended: true,
        };
    },
    getActiveOrNextDates(starting, ending, repeat) {
        const now = new Date();
        if (repeat == "never") {
            return { start: starting, end: ending };
        }
        if (repeat == "weekly") {
            var thisWeekStart = this.addWeeks(starting, this.diffWeeks(now, starting));
            var thisWeekEnd = this.addWeeks(ending, this.diffWeeks(now, ending));
            if (this.dealIsActive(thisWeekStart, thisWeekEnd)) {
                return { start: thisWeekStart, end: thisWeekEnd };
            } else {
                return { start: this.addWeeks(thisWeekStart, 1), end: this.addWeeks(thisWeekEnd, 1) };
            }
        }
        if (repeat == "daily") {
            return { start: starting, end: ending };
        }
        if (repeat == "weekdays") {
            return { start: starting, end: ending };
        }
    },
    dealIsActive(starting, ending) {
        const now = new Date();
        if (starting < now && now < ending) {
            return true;
        } else {
            return false;
        }
    },
    diffWeeks(a, b) {
        return Math.round((a - b) / 604800000);
    },
    diffDays(a, b) {
        return Math.round((a - b) / 86400000);
    },
    addWeeks(dateObj, numberOfWeeks) {
        dateObj.setDate(dateObj.getDate() + numberOfWeeks * 7);
        return dateObj;
    },
    addDays(dateObj, numberOfDays) {
        dateObj.setDate(dateObj.getDate() + numberOfDays);
        return dateObj;
    },
    getReadableTimeStatus(starting, ending, repeat) {
        const now = new Date();
        const startingDate = this.formatDateTimeToDate(starting);
        const endingDate = this.formatDateTimeToDate(ending);

        const { start, end } = this.getActiveOrNextDates(startingDate, endingDate, repeat);

        if (repeat == "weekdays") {
            return "weekdays";
        }
        if (repeat == "daily") {
            return "daily";
        }

        if (this.dealIsActive(start, end)) {
            return "ends " + this.readableTimeUntil(endingDate);
        } else {
            if (start > now && end > now) {
                return "starts " + this.readableTimeUntil(startingDate);
            } else {
                if (repeat == "never") {
                    return "ended " + this.readableTimeUntil(endingDate);
                }
            }
        }
    },
};
