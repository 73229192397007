import "react-native-gesture-handler";
import { StatusBar } from "expo-status-bar";
import { SafeAreaProvider } from "react-native-safe-area-context";
import useCachedResources from "./hooks/useCachedResources";
import Navigation from "./navigation";
import { NativeBaseProvider } from "native-base";
import Theme from "./config/Theme";
import { AuthContext } from "./context/AuthContext";
import { UserContext } from "./context/UserContext";
import { SearchContext } from "./context/SearchContext";
import { useEffect, useState } from "react";
import { UserLocationContext } from "./context/UserLocationContext";
import * as Analytics from "expo-firebase-analytics";
import {
    useFonts,
    Poppins_300Light,
    Poppins_400Regular,
    Poppins_600SemiBold,
    Poppins_700Bold,
    Poppins_400Regular_Italic,
    Poppins_700Bold_Italic,
} from "@expo-google-fonts/poppins";

export default function App() {
    // Context for authentication
    const [auth, setAuth] = useState(false);
    const authContext = { auth, setAuth };

    // Context for user information
    const [user, setUser] = useState(null);
    const userContext = { user, setUser };

    // Using react context to store deal search term
    const [search, setSearch] = useState("");
    const searchContext = { search, setSearch };

    // User location context
    const [userLocation, setUserLocation] = useState(null);
    const userLocationContext = { userLocation, setUserLocation };

    // Load Poppins Font
    const [fontsLoaded] = useFonts({
        Poppins_300Light,
        Poppins_400Regular,
        Poppins_400Regular_Italic,
        Poppins_600SemiBold,
        Poppins_700Bold,
        Poppins_700Bold_Italic,
    });

    useEffect(() => {
        Analytics.setAnalyticsCollectionEnabled(process.env.NODE_ENV === "production");
    }, []);

    const isLoadingComplete = useCachedResources();
    global.__reanimatedWorkletInit = () => {};

    if (!isLoadingComplete && !fontsLoaded) {
        return null;
    } else {
        return (
            <SafeAreaProvider>
                <AuthContext.Provider value={authContext}>
                    <UserContext.Provider value={userContext}>
                        <UserLocationContext.Provider value={userLocationContext}>
                            <SearchContext.Provider value={searchContext}>
                                <NativeBaseProvider theme={Theme}>
                                    <Navigation />
                                    <StatusBar />
                                </NativeBaseProvider>
                            </SearchContext.Provider>
                        </UserLocationContext.Provider>
                    </UserContext.Provider>
                </AuthContext.Provider>
            </SafeAreaProvider>
        );
    }
}
