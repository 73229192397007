import { View, Text } from "react-native";
import React from "react";
import { Box, Button, Icon, IconButton, Modal } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

export default function BasicModal({ show, onClose, header, buttons, children }) {
    return (
        <Modal isOpen={show} onClose={onClose}>
            <Modal.Content maxWidth="400px">
                <Modal.Header>{header}</Modal.Header>
                <Modal.Body>
                    <Box paddingY={2}>{children}</Box>
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group space={2}>{buttons}</Button.Group>
                </Modal.Footer>
            </Modal.Content>
        </Modal>
    );
}
