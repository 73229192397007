import { View, Text } from "react-native";
import React from "react";
import { Avatar, Box, Heading, VStack } from "native-base";
import ImageLoader from "../Loader/ImageLoader";

export default function BusinessHeader({ image, businessName, shopName }) {
    return (
        <VStack space={5} alignItems="center">
            <Box width={120} height={120} borderRadius={100} overflow="hidden" bg={"gray.200"}>
                <ImageLoader
                    source={{
                        uri: image,
                    }}
                    style={{
                        width: 120,
                        height: 120,
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                    }}
                    borderRadius={100}
                ></ImageLoader>
            </Box>

            <VStack space={2} alignItems={"center"}>
                <Heading>{businessName}</Heading>
                <Heading>{shopName}</Heading>
            </VStack>
        </VStack>
    );
}
