import React from "react";
import { TouchableOpacity } from "react-native-gesture-handler";
import { Box, HStack, Text } from "native-base";
import { MaterialIcons } from "@expo/vector-icons";

export default function CustomToast({ onPress, text, action }) {
    return (
        <TouchableOpacity onPress={onPress}>
            <Box bg="success.100" px="3" shadow="5" py="3" borderRadius={10} overflow="hidden" mb={5}>
                <Text color="success.800">{text}</Text>
                {action && (
                    <Text>
                        {action}
                        <Box px={2}>
                            <MaterialIcons name="arrow-forward" />
                        </Box>
                    </Text>
                )}
            </Box>
        </TouchableOpacity>
    );
}
