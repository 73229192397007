import { TouchableOpacity, View } from "react-native";
import React from "react";
import { Avatar, Box, ChevronRightIcon, Heading, HStack, Text, VStack } from "native-base";

export default function ListItemButton({ label, onPress, chevron, danger }) {
    return (
        <TouchableOpacity onPress={onPress}>
            <HStack justifyContent="space-between" alignItems="center" height={60} px={3}>
                <Text fontSize={"md"} color={danger && "error.500"}>
                    {label}
                </Text>
                {chevron && <ChevronRightIcon />}
            </HStack>
        </TouchableOpacity>
    );
}
