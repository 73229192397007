import React, { useContext, useState } from "react";
import { UserContext } from "../context/UserContext";
import AuthSplash from "../components/Auth/AuthSplash";
import Container from "../components/Container/Container";
import { Heading, HStack, useColorModeValue, VStack } from "native-base";
import SafeArea from "../components/SafeArea/SafeArea";
import DealsList from "../components/List/DealsList";
import api from "../services/api";
import { AuthContext } from "../context/AuthContext";
import { StatusBar } from "expo-status-bar";
import helpers from "../utils/helpers";

export default function SavedScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);
    const [saves, setSaves] = useState(null);

    React.useEffect(() => {
        const unsubscribe = navigation.addListener("focus", () => {
            getSaves();
        });
    }, [auth, navigation]);

    function getSaves() {
        if (auth) {
            api.save()
                .getAll()
                .then((res) => {
                    const deals = res.data.body.map((item) => {
                        return item.deal;
                    });
                    var savedDeals = helpers.convertUTCDateTimesToLocal(deals, ["starting", "ending"]);
                    setSaves(savedDeals);
                })
                .catch(() => {});
        }
    }
    return (
        <>
            <SafeArea />
            <StatusBar style={useColorModeValue("dark", "light")} animated={true} />
            {user ? (
                <Container maxWidth={600}>
                    <VStack p={4} space={8}>
                        <HStack justifyContent={"space-between"} alignItems="center">
                            <Heading size="xl">Saved</Heading>
                        </HStack>
                        <DealsList deals={saves} link={"/saved/deal"} emptyHeading={"No saved deals"} />
                    </VStack>
                </Container>
            ) : (
                <AuthSplash heading="Save deals" text={"Login or create an account to save your favourite deals."} />
            )}
        </>
    );
}
