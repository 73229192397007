import { AspectRatio, Box, Button, Heading, StatusBar, Text, VStack } from "native-base";
import { ScrollView } from "react-native-gesture-handler";
import Container from "../components/Container/Container";
import ImageLoader from "../components/Loader/ImageLoader";

export default function ForBusinessScreen({ route, navigation }) {
    function step(number) {
        return (
            <Box
                bg="primary.600"
                borderRadius={"full"}
                width={12}
                height={12}
                alignItems="center"
                justifyContent={"center"}
            >
                <Text fontSize={18} color={"white"}>
                    {number}
                </Text>
            </Box>
        );
    }

    function stepItem(stepNumber, title, text, maxWidth) {
        return (
            <VStack space={5} alignItems="center" py={16} px={4} bg={"dark.50"}>
                {step(stepNumber)}
                <Heading size="md" color="black">
                    {title}
                </Heading>
                <Text color="gray.500" textAlign="center" fontSize={16} maxW={maxWidth || 300}>
                    {text}
                </Text>
            </VStack>
        );
    }

    return (
        <ScrollView>
            <StatusBar style="dark" animated={true} />
            <AspectRatio
                width={"100%"}
                ratio={{
                    base: 4 / 3,
                    md: 24 / 9,
                }}
                style={{ transform: [{ scaleX: -1 }] }}
            >
                <ImageLoader
                    source={{
                        uri: "https://images.unsplash.com/photo-1592861956120-e524fc739696?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80",
                    }}
                    style={{
                        width: "100%",
                        height: 300,
                    }}
                ></ImageLoader>
            </AspectRatio>

            <AspectRatio
                style={{ position: "absolute", top: 0 }}
                width={"100%"}
                ratio={{
                    base: 4 / 3,
                    md: 24 / 9,
                }}
            >
                <Box
                    p={4}
                    alignItems="flex-start"
                    justifyContent={"center"}
                    bg={"black"}
                    background={"rgba(0,0,0,0.4)"}
                >
                    <Container transparent>
                        <Heading color={"white"} size={"2xl"} lineHeight={"2xs"}>
                            Make a {"\n"}
                            deal with {"\n"}
                            your {"\n"}customers
                        </Heading>
                    </Container>
                </Box>
            </AspectRatio>

            <Box px={4} py={12} bg="primary.600" alignItems={"center"}>
                <Text color={"white"} fontSize={20} textAlign="center" maxW={400}>
                    Dealio offers your business exposure to a marketplace of peckish people.
                </Text>
            </Box>

            <Box bg="white">
                {stepItem(
                    1,
                    "Managing deals made easy!",
                    "Our app makes it simple to create and manage your time based and weekly deals.",
                    274
                )}
                <Container maxWidth={600} transparent>
                    <ImageLoader
                        source={require("./../assets/images/1-app-easy.png")}
                        style={{
                            width: "100%",
                            height: 300,
                        }}
                    ></ImageLoader>
                </Container>
            </Box>

            <Box bg="white">
                {stepItem(
                    2,
                    "Engage the people around you.",
                    "We focus on location and make it simple for customers. They’re in your local area and looking for great deals.",
                    320
                )}
                <Container maxWidth={600} transparent>
                    <ImageLoader
                        source={require("./../assets/images/2-engage.png")}
                        style={{
                            width: "100%",
                            height: 300,
                        }}
                    ></ImageLoader>
                </Container>
            </Box>

            <Box bg="white">
                {stepItem(
                    3,
                    "Connect with new customers.",
                    "Expose your business to people open to trying new places and bring in new customers.",
                    300
                )}
                <Container maxWidth={600} transparent>
                    <ImageLoader
                        source={{
                            uri: "https://images.unsplash.com/photo-1541475074124-af32f4cb0dbb?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1175&q=80",
                        }}
                        style={{
                            width: "100%",
                            height: 300,
                        }}
                    ></ImageLoader>
                </Container>
            </Box>

            <Box p={4} py={12} alignItems="center" bg={"dark.50"}>
                <Heading size={"lg"} textAlign="center">
                    Dealio's free for {"\n"} a limited time.
                </Heading>
            </Box>
            <Box bg="white" p={4} py={24} alignItems="center">
                <Heading size={"lg"} textAlign="center">
                    Get started now!
                </Heading>
                <Text textAlign="center">It only takes 3 minutes.</Text>
                <Button mt={5} onPress={() => navigation.navigate("auth", { screen: "register" })}>
                    Let's get started
                </Button>
            </Box>
        </ScrollView>
    );
}
