import axios from "axios";
import { StatusBar } from "expo-status-bar";
import { ScrollView, Toast, useColorModeValue, VStack } from "native-base";
import { useContext, useEffect, useState } from "react";
import AuthSplash from "../components/Auth/AuthSplash";
import ListItemButton from "../components/Button/ListItemButton";
import Container from "../components/Container/Container";
import SafeArea from "../components/SafeArea/SafeArea";
import CustomToast from "../components/Toast/CustomToast";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import storage from "../services/storage";
import * as Analytics from "expo-firebase-analytics";
import { useLinkTo } from "@react-navigation/native";

export default function ProfileScreen({ navigation }) {
    const linkTo = useLinkTo();
    const [business, setBusiness] = useState(null);
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);

    useEffect(() => {
        user && setBusiness(user.business);
    }, [user]);

    function logout() {
        api.auth()
            .logout()
            .then(() => {
                Toast.show({
                    placement: "top",
                    render: () => {
                        return <CustomToast text={`Logged out.`} />;
                    },
                });
                setUser(null);
                setAuth(false);
                storage.removeItem("token");
                delete axios.defaults.headers.common["Authorization"];
                Analytics.logEvent("logged_out", {
                    sender: "action",
                    user: user?.id,
                    purpose: "Logged out",
                });
                Analytics.setUserId(null);
                navigation.navigate("welcome");
            })
            .catch((err) => {});
    }

    return (
        <>
            <StatusBar style={useColorModeValue("dark", "light")} animated />

            {user ? (
                <ScrollView>
                    <SafeArea transparent />
                    <Container maxWidth={400}>
                        <VStack p={4} mt={4}>
                            <ListItemButton
                                onPress={() => navigation.navigate("editprofile")}
                                label="Account"
                                chevron
                            />

                            {user?.business == null && (
                                <ListItemButton
                                    onPress={() => navigation.navigate("onboard")}
                                    label="Setup a business"
                                    chevron
                                />
                            )}

                            {user?.business?.is_verified == 0 && (
                                <ListItemButton
                                    onPress={() => navigation.navigate("editbusinessprofile")}
                                    label="Business profile (pending)"
                                    chevron
                                />
                            )}

                            {user?.business?.is_verified == 1 && (
                                <ListItemButton
                                    onPress={() => navigation.navigate("editbusinessprofile")}
                                    label="Business profile "
                                    chevron
                                />
                            )}

                            <ListItemButton onPress={() => navigation.navigate("contact")} label="Contact us" />

                            <ListItemButton onPress={() => navigation.navigate("terms")} label="Terms of use" />
                            <ListItemButton onPress={() => navigation.navigate("privacy")} label="Privacy policy" />

                            <ListItemButton onPress={() => logout()} label="Log out" danger />
                        </VStack>
                    </Container>
                </ScrollView>
            ) : (
                <AuthSplash heading="Profile" text="Manage your Dealio profile" />
            )}
        </>
    );
}
