import { Link } from "@react-navigation/native";
import { StatusBar } from "expo-status-bar";
import { Button, Center, Heading, Input, Text, Toast, VStack } from "native-base";
import { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import { ScrollView } from "react-native-gesture-handler";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Container from "../components/Container/Container";
import CustomToast from "../components/Toast/CustomToast";
import ActionBar from "../components/Topbar/ActionBar";
import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import authentication from "../services/authentication";
import axiosIntercept from "../services/axiosIntercept";
import storage from "../services/storage";
import * as Analytics from "expo-firebase-analytics";
import SafeArea from "../components/SafeArea/SafeArea";

export default function RegisterScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);

    const [formData, setData] = useState({
        email: "",
        password: "",
        confirm_password: "",
    });

    const [errors, setErrors] = useState({});

    useEffect(() => {
        const name = authentication.getDeviceName();
        setData({ ...formData, device_name: name });
    }, []);

    const validate = () => {
        if (formData.email === "") {
            setErrors({ email: "Email is required" });
            return false;
        } else if (formData.password === "") {
            setErrors({
                password: "Password is required",
            });
            return false;
        } else if (formData.password.length < 8) {
            setErrors({
                password: "Password is too short",
            });
            return false;
        } else if (formData.password_confirmation !== formData.password) {
            setErrors({
                password_confirmation: "Passwords do not match",
            });
            return false;
        } else {
            setErrors({});
            return true;
        }
    };

    function register() {
        api.auth()
            .register(formData)
            .then((res) => {
                setUser(res.data.body.user);
                Analytics.logEvent("signed_up", {
                    sender: "action",
                    user: res.data.body.user?.id,
                    screen: `Register`,
                    purpose: "Signed up",
                });
                axiosIntercept.setAuthHeader(res.data.body.token);
                storage
                    .setItem("token", res.data.body.token)
                    .then(() => {
                        setAuth(true);
                    })
                    .then(() => {
                        navigation.pop();
                        navigation.navigate("welcome");
                        Toast.show({
                            placement: "top",
                            render: () => {
                                return <CustomToast text={`Welcome to Dealio!`} />;
                            },
                        });
                    });
            })
            .catch((err) => {
                err.response && setErrors(err.response.data.errors);
            });
    }

    return (
        <>
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <SafeArea transparent />
                <ActionBar padding={4} close closeOnPress={() => navigation.goBack()} />

                <ScrollView>
                    {Platform.OS == "ios" && <StatusBar style="light" animated={true} />}
                    <Container maxWidth={400}>
                        <VStack space={3} padding={8}>
                            <Heading size="xl" marginBottom={4} maxWidth={270}>
                                Join Dealio!
                            </Heading>

                            <Input
                                key={1}
                                value={formData.email || null}
                                placeholder="Email"
                                onChangeText={(value) =>
                                    setData({
                                        ...formData,
                                        email: value,
                                    })
                                }
                            ></Input>
                            {"email" in errors && <Text color={"error.500"}>{errors.email}</Text>}

                            <Input
                                key={2}
                                onEndEditing={(event) => {
                                    if (event.nativeEvent.text.length === 0) {
                                        setData({
                                            ...formData,
                                            password: "",
                                        });
                                    }
                                }}
                                placeholder="Password"
                                type="password"
                                value={formData.password || ""}
                                onChangeText={(value) =>
                                    setData({
                                        ...formData,
                                        password: value,
                                    })
                                }
                            ></Input>
                            {"password" in errors && <Text color={"error.500"}>{errors.password}</Text>}

                            <Input
                                key={3}
                                placeholder="Repeat password"
                                onEndEditing={(event) => {
                                    if (event.nativeEvent.text.length === 0) {
                                        setData({
                                            ...formData,
                                            password_confirmation: "",
                                        });
                                    }
                                }}
                                type="password"
                                value={formData.password_confirmation || ""}
                                onChangeText={(value) =>
                                    setData({
                                        ...formData,
                                        password_confirmation: value,
                                    })
                                }
                            ></Input>

                            {"password_confirmation" in errors && (
                                <Text color={"error.500"}>{errors.password_confirmation}</Text>
                            )}

                            <Text fontSize="xs" ml={3} textAlign="center" my={3} color={"gray.500"}>
                                By clicking Sign Up, you are agreeing to Dealio's{" "}
                                <Link to={"/terms"}>
                                    <Text color={"primary.500"}>Terms of Use</Text>{" "}
                                </Link>
                                and are acknowledging our{" "}
                                <Link to={"/privacy"}>
                                    {" "}
                                    <Text color={"primary.500"}>Privacy Policy</Text>
                                </Link>{" "}
                                applies.
                            </Text>

                            <Button
                                onPress={() => {
                                    validate() && register();
                                }}
                            >
                                Create account
                            </Button>
                        </VStack>
                        <Center mb={10}>
                            <Text>Already have an account?</Text>
                            <Button variant="link" onPress={() => navigation.navigate("login")}>
                                Login
                            </Button>
                        </Center>
                    </Container>
                </ScrollView>
            </KeyboardAwareScrollView>
        </>
    );
}
