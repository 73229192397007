import axios from "axios";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import storage from "./storage";

export default {
    setup() {
        var tokenIsSet = false;

        axios.interceptors.request.use((request) => {
            storage.getItem("token").then((token) => {
                if (token) {
                    tokenIsSet = true;
                    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
                } else {
                    delete axios.defaults.headers.common["Authorization"];
                }
            });
            return request;
        });

        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                return Promise.reject(error);
            }
        );

        return tokenIsSet;
    },
    async setAuthHeader(token) {
        axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    },
};
