import { StatusBar } from "expo-status-bar";
import { Box, Button, FormControl, Heading, HStack, Input, Text, Toast, VStack } from "native-base";
import { useContext, useEffect, useState } from "react";
import { Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import PendingVerificationAlert from "../components/Alert/PendingVerificationAlert";
import AddressAutocomplete from "../components/Autocomplete/AddressAutocomplete";
import Container from "../components/Container/Container";
import BasicModal from "../components/Modal/BasicModal";
import SafeArea from "../components/SafeArea/SafeArea";
import CustomToast from "../components/Toast/CustomToast";
import ActionBar from "../components/Topbar/ActionBar";
import LogoUploader from "../components/Upload/LogoUploader";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import helpers from "../utils/helpers";
import * as Analytics from "expo-firebase-analytics";

export default function EditBusinessProfileScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);

    const [formData, setData] = useState({});
    const [errors, setErrors] = useState({});

    const [business, setBusiness] = useState(null);

    const [updating, setUpdating] = useState(false);

    const [showBusinessDeleteModal, setShowBusinessDeleteModal] = useState(false);
    const [deletingBusiness, setDeletingBusiness] = useState(false);

    useEffect(() => {
        setBusiness(user?.business);
    }, [user]);

    useEffect(() => {
        getAuthUser();
    }, []);

    const validate = () => {
        setErrors({});
        return true;
    };

    function getAuthUser() {
        return api
            .auth()
            .user()
            .then((res) => {
                setBusiness(res.data.body.business);
                setUser(res.data.body);
            });
    }

    function updateBusiness() {
        setUpdating(true);
        return api
            .business()
            .update({
                id: business.id,
                business_name: formData.business_name,
                shop_name: formData.shop_name,
                address: formData.address,
                lat: formData.lat,
                lng: formData.lng,
            })
            .then((res) => {
                setUpdating(false);
                formData.logo ? uploadLogo() : onUpdateSuccess();
            })
            .catch((err) => {
                setUpdating(false);
                setErrors(err.response.data.errors);
            });
    }

    function uploadLogo() {
        let fd = new FormData();

        if (Platform.OS == "web") {
            const file = new File([formData.logo.blob], formData.logo.name, {
                type: `image/${formData.logo.type}`,
            });
            fd.append("logo", file);
        } else {
            fd.append("logo", { type: formData.logo.type, uri: formData.logo.uri, name: formData.logo.name });
        }

        return api
            .business()
            .uploadLogo(business?.id, fd)
            .then((res) => onUpdateSuccess())
            .catch((err) => setErrors(err.response.data.errors));
    }

    function onUpdateSuccess() {
        Toast.show({
            placement: "top",
            render: () => {
                return <CustomToast text={`Changes saved.`} />;
            },
        });
        getAuthUser();
    }

    function deleteBusiness() {
        setDeletingBusiness(true);
        api.business()
            .delete({ id: business?.id })
            .then(() => {
                Analytics.logEvent("delete_business", {
                    sender: "action",
                    user: user?.id,
                    screen: `Business profile`,
                    purpose: "Delete a business",
                });
                getAuthUser();
                setDeletingBusiness(false);
                setShowBusinessDeleteModal(false);
                navigation.pop();
                navigation.navigate("welcome");
                Toast.show({
                    placement: "top",
                    render: () => {
                        return <CustomToast text={`Your businesses "${business?.business_name}" has been deleted.`} />;
                    },
                });
            })
            .catch((err) => {
                setDeletingBusiness(false);
                setErrors(err.response.data.errors);
            });
    }

    return (
        <>
            <StatusBar />
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <SafeArea />
                <Container maxWidth={600}>
                    <Box p={4}>
                        <ActionBar back backOnPress={() => navigation.goBack()} />
                    </Box>

                    {user?.business?.is_verified == false ? (
                        <Box p={4}>
                            <PendingVerificationAlert business={user.business.business_name} />
                        </Box>
                    ) : (
                        <>
                            <LogoUploader
                                image={formData?.logo || helpers.rebaseImage(business?.logo?.thumb)}
                                onChange={(result) => setData({ ...formData, logo: result })}
                            />

                            <VStack p={4} space={4}>
                                {errors && "logo" in errors && (
                                    <Text marginTop={3} color={"error.500"}>
                                        {errors.logo}
                                    </Text>
                                )}

                                <FormControl>
                                    <FormControl.Label>Business name</FormControl.Label>
                                    <Input
                                        type="text"
                                        defaultValue={business?.business_name}
                                        onChangeText={(value) =>
                                            setData({
                                                ...formData,
                                                business_name: value,
                                            })
                                        }
                                        isDisabled
                                    />
                                    {errors && "business_name" in errors && (
                                        <Text color={"error.500"}>{errors.business_name}</Text>
                                    )}
                                </FormControl>

                                <FormControl>
                                    <FormControl.Label>Shop name</FormControl.Label>
                                    <Input
                                        type="text"
                                        defaultValue={business?.shop_name}
                                        onChangeText={(value) =>
                                            setData({
                                                ...formData,
                                                shop_name: value,
                                            })
                                        }
                                        isDisabled
                                    />
                                    {errors && "shop_name" in errors && (
                                        <Text color={"error.500"}>{errors.shop_name}</Text>
                                    )}
                                </FormControl>

                                <AddressAutocomplete
                                    defaultValue={business?.address}
                                    onSelect={(addr) =>
                                        setData({
                                            ...formData,
                                            address: addr.place_name,
                                            lat: addr.center[1],
                                            lng: addr.center[0],
                                        })
                                    }
                                    isDisabled
                                />
                                {errors && "address" in errors && <Text color={"error.500"}>{errors.address}</Text>}
                            </VStack>

                            <Box p={4} pb={10}>
                                <VStack space={2} alignItems="flex-start">
                                    <Heading size="sm">Business deletion</Heading>
                                    <Text>
                                        Delete the business account associated with your user account. This will also
                                        remove your business' deals from Dealio.
                                    </Text>
                                    <Button
                                        onPress={() => setShowBusinessDeleteModal(true)}
                                        variant="outline"
                                        colorScheme="error"
                                    >
                                        Delete business
                                    </Button>
                                </VStack>
                            </Box>
                        </>
                    )}
                </Container>
            </KeyboardAwareScrollView>

            <BasicModal
                header={`Delete ${business?.business_name} ${business?.shop_name}`}
                show={showBusinessDeleteModal}
                children={
                    <Text>
                        Are you sure you want to delete your business? This will delete all your deals and cannot be
                        undone.
                    </Text>
                }
                buttons={
                    <HStack space={2}>
                        <Button variant="ghost" onPress={() => setShowBusinessDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            onPress={() => deleteBusiness()}
                            isLoading={deletingBusiness}
                            isLoadingText={"Deleting business"}
                        >
                            Confirm
                        </Button>
                    </HStack>
                }
            ></BasicModal>
        </>
    );
}
