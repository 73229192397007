import {
    Badge,
    Box,
    Button,
    Column,
    Fade,
    FormControl,
    Heading,
    HStack,
    Input,
    Row,
    Spinner,
    Text,
    TextArea,
    Toast,
    VStack,
} from "native-base";
import { useEffect, useState } from "react";
import { Platform } from "react-native";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Container from "../components/Container/Container";
import CurrencyInput from "../components/Input/CurrencyInput";
import DateTimeInput from "../components/Input/DateTimeInput";
import SelectInput from "../components/Input/SelectInput";
import BasicModal from "../components/Modal/BasicModal";
import SafeArea from "../components/SafeArea/SafeArea";
import CustomToast from "../components/Toast/CustomToast";
import ActionBar from "../components/Topbar/ActionBar";
import ImageUploader from "../components/Upload/ImageUploader";
import api from "../services/api";
import helpers from "../utils/helpers";
import * as Analytics from "expo-firebase-analytics";

export default function EditDealScreen({ route, navigation }) {
    const [deal, setDeal] = useState(null);
    const [showDeleteModal, setShowDeleteModal] = useState(false);

    const [formData, setData] = useState({});
    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});

    const [updating, setUpdating] = useState(false);

    let { id } = route.params;

    useEffect(() => {
        getDeal();
    }, []);

    function getDeal() {
        api.deal()
            .getOne({ id: id })
            .then((res) => {
                var dealData = helpers.convertUTCDateTimesToLocal(res.data.body, ["starting", "ending"]);
                setDeal(dealData);
            });
    }

    function deleteDeal() {
        api.deal()
            .delete({ id: id })
            .then((res) => {
                setShowDeleteModal(false);
                Analytics.logEvent("deleted_deal", {
                    sender: "action",
                    user: user?.id,
                    screen: `Edit Deal`,
                    dealId: id,
                    purpose: "Deleted a deal",
                });
                navigation.navigate("home", { refetch: true });
            });
    }

    async function onSubmit() {
        for (const key in errors) {
            delete errors[key];
        }
        if (validate()) {
            updateDeal();
        }
    }

    const validate = () => {
        if (formData.title === null || formData.title === "") {
            setErrors({ ...errors, title: "Title is required" });
            return false;
        } else if (formData.starting === null) {
            setErrors({
                ...errors,
                starting: "Starting date and time is required",
            });
            return false;
        } else if (formData.ending === null) {
            setErrors({
                ...errors,
                ending: "Ending date and time is required",
            });
            return false;
        } else {
            return true;
        }
    };

    function updateDeal() {
        setUpdating(true);
        return api
            .deal()
            .update({ id: deal.id, ...formData })
            .then((res) => {
                setUpdating(false);
                setDeal(res.data.body);
                image ? uploadImage() : onUpdateSuccess();
            })
            .catch((err) => {
                setUpdating(false);
                setErrors(err.response.data.errors);
            });
    }

    async function uploadImage() {
        let fd = new FormData();

        if (Platform.OS == "web") {
            const file = new File([image.blob], image.name, {
                type: `image/${image.type}`,
            });
            fd.append("image", file);
        } else {
            fd.append("image", { type: image.type, uri: image.uri, name: image.name });
        }

        return api
            .deal()
            .uploadImage(id, fd)
            .then((res) => {
                onUpdateSuccess();
            })
            .catch((err) => {
                setErrors(err?.response?.data?.errors);
            });
    }

    function onUpdateSuccess() {
        Toast.show({
            placement: "bottom",
            render: () => {
                return <CustomToast text={`"${deal.title}" saved.`} />;
            },
        });

        Analytics.logEvent("updated_deal", {
            sender: "action",
            user: user?.id,
            screen: `Edit Deal`,
            dealId: deal?.id,
            purpose: "Updated a deal",
        });

        navigation.navigate("home", { refetch: true });
    }

    return deal ? (
        <>
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <SafeArea />
                <Container maxWidth={600}>
                    <VStack space={5} padding={4}>
                        <Fade in={deal}>
                            <VStack space={5}>
                                <ActionBar back backOnPress={() => navigation.goBack()} />
                                <Heading size="xl">{deal.title}</Heading>

                                {deal?.original_price && deal?.deal_price && (
                                    <HStack space={2} alignItems={"center"}>
                                        <Heading size={"xs"} color={"gray.400"} strikeThrough>
                                            {helpers.intToPrice(deal?.original_price)}
                                        </Heading>
                                        <Heading size={"sm"} color={"primary.500"}>
                                            {helpers.intToPrice(deal?.deal_price)}
                                        </Heading>
                                        <Badge colorScheme="success">
                                            {`${helpers.percentageChange(deal?.original_price, deal?.deal_price)} off`}
                                        </Badge>
                                    </HStack>
                                )}

                                {errors && "image" in errors && (
                                    <Text marginTop={3} color={"error.500"}>
                                        {errors.image}
                                    </Text>
                                )}

                                <ImageUploader
                                    image={image || helpers.rebaseImage(deal?.image?.thumb)}
                                    onChange={(result) => setImage(result)}
                                />

                                <FormControl isRequired>
                                    <FormControl.Label>Title</FormControl.Label>
                                    <Input
                                        type="text"
                                        placeholder="e.g. Burger, Chips & Drink"
                                        defaultValue={deal.title}
                                        onChangeText={(value) =>
                                            setData({
                                                ...formData,
                                                title: value,
                                            })
                                        }
                                    />
                                    {errors && "title" in errors && (
                                        <Text marginTop={3} color={"error.500"}>
                                            {errors?.title}
                                        </Text>
                                    )}
                                </FormControl>

                                <FormControl>
                                    <FormControl.Label>Description</FormControl.Label>
                                    <TextArea
                                        numberOfLines={6}
                                        type="textarea"
                                        placeholder="Some more info about your deal"
                                        defaultValue={deal.description}
                                        onChangeText={(value) =>
                                            setData({
                                                ...formData,
                                                description: value,
                                            })
                                        }
                                    />
                                    {errors && "description" in errors && (
                                        <Text marginTop={3} color={"error.500"}>
                                            {errors?.description}
                                        </Text>
                                    )}
                                </FormControl>

                                <Row space={5}>
                                    <Column width={"50%"}>
                                        <CurrencyInput
                                            label="Original price"
                                            defaultValue={deal.original_price && deal.original_price.toFixed(2) / 100}
                                            onChangeText={(value) =>
                                                setData({
                                                    ...formData,
                                                    original_price: parseInt(parseFloat(value) * 100),
                                                })
                                            }
                                        />
                                    </Column>

                                    <Column width={"50%"}>
                                        <CurrencyInput
                                            label="Deal price"
                                            defaultValue={deal.deal_price && deal.deal_price.toFixed(2) / 100}
                                            onChangeText={(value) =>
                                                setData({
                                                    ...formData,
                                                    deal_price: parseInt(parseFloat(value) * 100),
                                                })
                                            }
                                        />
                                    </Column>
                                </Row>

                                <DateTimeInput
                                    defaultValue={helpers.formatDateTimeToDate(deal.starting)}
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            starting: value,
                                        })
                                    }
                                    isRequired
                                    label="Starting"
                                />
                                {errors && "starting" in errors && (
                                    <Text marginTop={3} color={"error.500"}>
                                        {errors.starting}
                                    </Text>
                                )}

                                <DateTimeInput
                                    defaultValue={helpers.formatDateTimeToDate(deal.ending)}
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            ending: value,
                                        })
                                    }
                                    isRequired
                                    label="Ending"
                                />
                                {errors && "ending" in errors && (
                                    <Text marginTop={3} color={"error.500"}>
                                        {errors.ending}
                                    </Text>
                                )}

                                <SelectInput
                                    defaultValue={deal?.repeat}
                                    options={[
                                        { value: "never", label: "never" },
                                        { value: "daily", label: "daily" },
                                        { value: "weekly", label: "weekly" },
                                        { value: "weekdays", label: "weekdays" },
                                    ]}
                                    onValueChange={(value) =>
                                        setData({
                                            ...formData,
                                            repeat: value,
                                        })
                                    }
                                    isRequired
                                    label="Repeat"
                                />
                                {errors && "repeat" in errors && (
                                    <Text marginTop={3} color={"error.500"}>
                                        {errors.repeat}
                                    </Text>
                                )}

                                <Button variant={"outline"} marginBottom={6} onPress={() => setShowDeleteModal(true)}>
                                    Delete deal
                                </Button>
                            </VStack>
                        </Fade>

                        <BasicModal
                            show={showDeleteModal}
                            header={"Delete deal"}
                            onClose={() => setShowDeleteModal(false)}
                            buttons={
                                <>
                                    <Button variant="ghost" onPress={() => setShowDeleteModal(false)}>
                                        Cancel
                                    </Button>
                                    <Button onPress={() => deleteDeal()}>Delete</Button>
                                </>
                            }
                        >
                            <Text>Are you sure you want to delete this deal?</Text>
                        </BasicModal>
                    </VStack>
                </Container>
            </KeyboardAwareScrollView>
            <Container maxWidth={600}>
                <Box p={4}>
                    <Button onPress={() => onSubmit()} isLoading={updating} isLoadingText="Saving">
                        Save
                    </Button>
                </Box>
            </Container>
        </>
    ) : (
        <Box flex={1} alignItems="center" justifyContent={"center"}>
            <Spinner />
        </Box>
    );
}
