import React, { useEffect, useState } from "react";
import { Box, Button } from "native-base";
import ImageLoader from "../Loader/ImageLoader";
import * as ImagePicker from "expo-image-picker";
import { Platform } from "react-native";

export default function ImageUploader({ image, onPress, onChange }) {
    const [previewImage, setPreviewImage] = useState(null);

    const fetchImageFromUri = async (uri) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };

    async function chooseImage() {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            quality: 1,
        });
        if (result.cancelled) {
            return;
        }
        // ImagePicker saves the taken photo to disk and returns a local URI to it
        let localUri = result.uri;
        let filename = localUri.split("/").pop();

        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        setPreviewImage(localUri);
        const img = await fetchImageFromUri(localUri);
        //onChange({ uri: localUri, name: filename, type });
        onChange({ blob: img, uri: localUri, name: filename, type });
    }

    return (
        <Box
            _light={{ bg: "dark.100" }}
            _dark={{ bg: "dark.800" }}
            borderRadius={"lg"}
            alignItems="center"
            justifyContent="center"
            style={{ overflow: "hidden", width: "100%", height: 200 }}
        >
            <ImageLoader
                source={{ uri: previewImage || image }}
                style={{
                    width: "100%",
                    height: 250,
                    alignItems: "center",
                    justifyContent: "center",
                    position: "absolute",
                }}
            ></ImageLoader>
            <Button variant={"subtle"} onPress={chooseImage}>
                {image ? "Change" : "Choose image"}
            </Button>
        </Box>
    );
}
