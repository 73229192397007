import { View } from "react-native";
import React from "react";
import { Box, Button, Text } from "native-base";

export default function AutocompleteDropdown({ items, onPress }) {
    return (
        <Box
            shadow={5}
            borderRadius={4}
            _light={{ bg: "white" }}
            _dark={{ bg: "dark.800" }}
            borderWidth={1}
            borderColor={"gray.400"}
            alignItems="flex-start"
        >
            {items && items.length > 0 ? (
                items.map((item) => (
                    <Button
                        borderRadius={0}
                        variant="ghost"
                        alignContent={"flex-start"}
                        p={2}
                        onPress={() => onPress(item)}
                    >
                        {item.label}
                    </Button>
                ))
            ) : (
                <Box p={3}>
                    <Text>no results</Text>
                </Box>
            )}
        </Box>
    );
}
