import React from "react";
import Container from "../components/Container/Container";
import { Box, Button, Heading, ScrollView, Text, VStack } from "native-base";
import ActionBar from "../components/Topbar/ActionBar";
import SafeArea from "../components/SafeArea/SafeArea";

export default function PrivacyScreen({ navigation }) {
    return (
        <ScrollView>
            <SafeArea />
            <Container maxWidth={600}>
                <VStack space={6} mb={24} p={4}>
                    <ActionBar back backOnPress={() => navigation.goBack()} />

                    <Heading size={"2xl"}>Privacy Policy</Heading>

                    <Text>
                        Dealio values and respects the privacy of the people we deal with. Dealio is committed to
                        protecting your privacy and complying with the Privacy Act 1988 (Cth) (Privacy Act) and other
                        applicable privacy laws and regulations.
                    </Text>
                    <Text>
                        This Privacy Policy (Policy) describes how we collect, hold, use and disclose your personal
                        information, and how we maintain the quality and security of your personal information.
                    </Text>

                    <Heading>What is personal information?</Heading>

                    <Text>
                        “Personal information” means any information or opinion, whether true or not, and whether
                        recorded in a material form or not, about an identified individual or an individual who is
                        reasonably identifiable. In general terms, this includes information or an opinion that
                        personally identifies you either directly (e.g. your name) or indirectly.
                    </Text>

                    <Heading>What personal information do we collect?</Heading>

                    <Text>
                        The personal information we collect about you depends on the nature of your dealings with us or
                        what you choose to share with us.
                    </Text>

                    <Text>The personal information we collect about you may include:</Text>

                    <Box>
                        <Text>{`\u2022 Name`}</Text>
                        <Text>{`\u2022 Street Address`}</Text>
                        <Text>{`\u2022 Email Address`}</Text>
                        <Text>{`\u2022 Location`}</Text>
                    </Box>

                    <Text>
                        You do not have to provide us with your personal information. Where possible, we will give you
                        the option to interact with us anonymously or by using a pseudonym. However, if you choose to
                        deal with us in this way or choose not to provide us with your personal information, we may not
                        be able to provide you with our services or otherwise interact with you.
                    </Text>

                    <Heading>How do we collect your personal information?</Heading>

                    <Text>We collect your personal information directly from you when you:</Text>

                    <Box>
                        <Text>{`\u2022 Interact with us over the phone`}</Text>
                        <Text>{`\u2022 Interact with us in person`}</Text>
                        <Text>{`\u2022 Interact with us online through the Dealio app`}</Text>
                        <Text>{`\u2022 Participate in surveys or questionaires`}</Text>
                        <Text>{`\u2022 Attend a Dealio event`}</Text>
                        <Text>{`\u2022 Subscribe to our mailing list`}</Text>
                        <Text>{`\u2022 Apply for a position as an employee, contractor or volunteer`}</Text>
                    </Box>

                    <Heading>Collecting personal information from third parties</Heading>

                    <Text>
                        We may also collect your personal information from third parties or through publicly available
                        sources, for example from social media platforms or search engines. We collect your personal
                        information from these third parties so that we can advertise Dealio to you.
                    </Text>

                    <Heading>How do we use your personal information?</Heading>

                    <Text>
                        We use personal information for many purposes in connection with our functions and activities,
                        including the following purposes:
                    </Text>

                    <Box>
                        <Text>{`\u2022 Provide you with information or services that you request from us`}</Text>
                        <Text>{`\u2022 Deliver to you a more personalised/localised experience and service offering`}</Text>
                        <Text>{`\u2022 Improve the quality of the services we offer`}</Text>
                        <Text>{`\u2022 Internal administrative purposes`}</Text>
                        <Text>{`\u2022 Marketing and research purposes`}</Text>
                    </Box>

                    <Heading>Disclosure of personal information to third parties</Heading>

                    <Text>
                        We may disclose your personal information to third parties in accordance with this Policy in
                        circumstances where you would reasonably expect us to disclose your information. For example, we
                        may disclose your personal information to:
                    </Text>

                    <Box>
                        <Text>{`\u2022 Our third party service providers (for example, our IT providers)`}</Text>
                        <Text>{`\u2022 Our marketing providers`}</Text>
                        <Text>{`\u2022 Our professional services advisors;`}</Text>
                    </Box>

                    <Heading>Transfer of personal information overseas</Heading>
                    <Text>
                        Some of the third-party service providers we disclose personal information to may be based in or
                        have servers located outside of Australia, including in Asia or the United States.
                    </Text>

                    <Text>
                        Where we disclose your personal information to third parties overseas, we will take reasonable
                        steps to ensure that data security and appropriate privacy practices are maintained. We will
                        only disclose to overseas third parties if:
                    </Text>

                    <Box>
                        <Text>{`\u2022 You have given us your consent to disclose personal information to that third party; or`}</Text>
                        <Text>{`\u2022 We reasonably believe that:`}</Text>
                        <Box ml={3}>
                            <Text>{`\u2022 The overseas recipient is subject to a law or binding scheme that is, overall, substantially similar to the
APPs; and`}</Text>
                            <Text>{`\u2022 The law or binding scheme can be enforced; or`}</Text>
                        </Box>
                        <Text>{`\u2022 The disclosure is required or authorised by an Australian law or court / tribunal order`}</Text>
                    </Box>

                    <Heading>How do we protect your personal information?</Heading>

                    <Text>
                        Dealio will take reasonable steps to ensure that the personal information that we hold about you
                        is kept confidential and secure, including by:
                    </Text>

                    <Text>{`\u2022 Having a robust physical security of our premises and databases / records`}</Text>
                    <Text>{`\u2022 Taking measures to restrict access to only personnel who need that personal information to effectively
provide services to you`}</Text>
                    <Text>{`\u2022 Having technological measures in place (for example, anti-virus software, fire walls)`}</Text>

                    <Heading>Online activity</Heading>

                    <Heading size={"md"}>Cookies</Heading>

                    <Text>
                        The Dealio website uses cookies. A cookie is a small file of letters and numbers the website
                        puts on your device if you allow it. These cookies recognise when your device has visited our
                        website(s) before, so we can distinguish you from other users of the website. This improves your
                        experience and the Dealio website(s).
                    </Text>

                    <Text>
                        We do not use cookies to identify you, just to improve your experience on our website(s). If you
                        do not wish to use the cookies, you can amend the settings on your internet browser so it will
                        not automatically download cookies. However, if you remove or block cookies on your computer,
                        please be aware that your browsing experience and our website’s functionality may be affected.]
                    </Text>

                    <Heading size={"md"}>Website analytics</Heading>

                    <Text>
                        Our website uses FullStory and Google Analytics to help us better understand visitor traffic, so
                        we can improve our services. Although this data is mostly anonymous, it is possible that under
                        certain circumstances, we may connect it to you.
                    </Text>

                    <Heading size={"md"}>Direct Marketing</Heading>

                    <Text>
                        We may send you direct marketing communications and information about our services,
                        opportunities, or events that we consider may be of interest to you if you have requested or
                        consented to receive such communications. These communications may be sent in various forms,
                        including mail, SMS, fax and email, in accordance with applicable marketing laws, such as the
                        Australian Spam Act 2003 (Cth). You consent to us sending you those direct marketing
                        communications by any of those methods. If you indicate a preference for a method of
                        communication, we will endeavour to use that method whenever practical to do so.
                    </Text>

                    <Text>
                        You may opt-out of receiving marketing communications from us at any time by following the
                        instructions to “unsubscribe'' set out in the relevant communication / contacting us using the
                        details set out in the “How to contact us” section below.
                    </Text>

                    <Text>
                        In addition, we may also use your personal information or disclose your personal information to
                        third parties for the purposes of advertising, including online behavioural advertising, website
                        personalisation, and to provide targeted or retargeted advertising content to you (including
                        through third party websites).
                    </Text>

                    <Heading>Retention of personal information</Heading>

                    <Text>
                        We will not keep your personal information for longer than we need to. In most cases, this means
                        that we will only retain your personal information for the duration of your relationship with us
                        unless we are required to retain your personal information to comply with applicable laws, for
                        example record-keeping obligations.
                    </Text>

                    <Heading>How to access and correct your personal information</Heading>

                    <Text>
                        Dealio will endeavour to keep your personal information accurate, complete and up to date.
                    </Text>

                    <Text>
                        If you wish to make a request to access and / or correct the personal information we hold about
                        you, you should make a request by contacting us and we will usually respond within 7 days.
                    </Text>

                    <Heading>Links to third party sites</Heading>

                    <Text>
                        Dealio's app and website(s) may contain links to websites operated by third parties. If you
                        access a third party website through our app or website(s), personal information may be
                        collected by that third party website. We make no representations or warranties in relation to
                        the privacy practices of any third party provider or website and we are not responsible for the
                        privacy policies or the content of any third party provider or website. Third party providers /
                        websites are responsible for informing you about their own privacy practices and we encourage
                        you to read their privacy policies.
                    </Text>

                    <Heading>Inquiries and complaints</Heading>

                    <Text>
                        For complaints about how Dealio handles, processes or manages your personal information, please
                        send an email to contact@dealioit.com. Note we may require proof of your identity and full
                        details of your request before we can process your complaint.
                    </Text>

                    <Text>
                        Please allow up to 7 days for Dealio to respond to your complaint. It will not always be
                        possible to resolve a complaint to everyone’s satisfaction. If you are not satisfied with
                        Dealio’s response to a complaint, you have the right to contact the Office of Australian
                        Information Commissioner (at www.oaic.gov.au/) to lodge a complaint.
                    </Text>

                    <Heading>How to contact us</Heading>

                    <Text>
                        If you have a question or concern in relation to our handling of your personal information or
                        this Policy, you can contact us for assistance as follows:
                    </Text>

                    <Heading size={"sm"}>Email</Heading>

                    <Box alignItems="flex-start">
                        <Button onPress={() => Linking.openURL("mailto:contact@dealioit.com")}>
                            contact@dealioit.com
                        </Button>
                    </Box>
                </VStack>
            </Container>
        </ScrollView>
    );
}
