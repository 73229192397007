import * as React from "react";
import MapView, { Callout, Marker } from "react-native-maps";
import { StyleSheet, View, Dimensions, Platform } from "react-native";
import {
    Avatar,
    Box,
    Button,
    Center,
    HStack,
    Icon,
    IconButton,
    Spinner,
    Text,
    useColorMode,
    useColorModeValue,
    useToken,
} from "native-base";
import { MaterialIcons } from "@expo/vector-icons";
import { useNavigation } from "@react-navigation/native";
import helpers from "../../utils/helpers";
import Logo from "../Logo/Logo";
import SafeArea from "../SafeArea/SafeArea";

export default function Map({
    bottomSheetPosition,
    showFullScreenButton,
    showBackButton,
    height,
    businesses,
    lat,
    lng,
    isLoading,
    borderRadius,
}) {
    const navigation = useNavigation();
    const userMarkerColor = useToken("colors", useColorModeValue("blue.600", "blue.400"));

    let mapRef = React.useRef();

    React.useEffect(() => {
        var bottomPadding1 = Platform.OS == "ios" ? 130 : 0;
        var bottomPadding2 = Platform.OS == "ios" ? 460 : 0;
        var coords = [];
        if (mapRef.current) {
            if (businesses && businesses.length > 0) {
                coords = businesses.map((business) => {
                    return {
                        latitude: business?.lat,
                        longitude: business?.lng,
                    };
                });
                coords = [...coords, { latitude: lat, longitude: lng }];
                mapRef.current.fitToCoordinates(coords, {
                    edgePadding: {
                        bottom: bottomSheetPosition == 0 ? bottomPadding1 : bottomPadding2,
                        right: 50,
                        top: 40,
                        left: 50,
                    },
                    animated: true,
                });
            } else {
                if (lat && lng) {
                    mapRef.current.animateToRegion(
                        {
                            latitude: Number(lat) - (bottomSheetPosition == 1 ? 0.025 : 0),
                            longitude: Number(lng),
                            latitudeDelta: 0.05,
                            longitudeDelta: 0.05,
                        },
                        500
                    );
                }
            }
        }
    }, [businesses, lat, lng, bottomSheetPosition]);

    return (
        <Box
            bg={"white"}
            overflow={"hidden"}
            shadow={5}
            width={"100%"}
            height={height}
            justifyContent="center"
            borderRadius={borderRadius}
        >
            {showBackButton && (
                <Box position={"absolute"} zIndex="10" left={2} top={20}>
                    <IconButton
                        icon={<Icon as={MaterialIcons} name="arrow-back" size="sm" color={"dark.900"} />}
                        onPress={() => navigation.goBack()}
                    />
                </Box>
            )}
            {showFullScreenButton && (
                <Box position={"absolute"} zIndex="10" right={2} bottom={2}>
                    <Button
                        onPress={() =>
                            navigation.navigate("map", {
                                lat: lat,
                                lng: lng,
                                businesses: businesses,
                            })
                        }
                    >
                        <MaterialIcons name="fullscreen" size={24} color={"white"} />
                    </Button>
                </Box>
            )}

            <Box position={"absolute"} top={3} left={2} zIndex={10}>
                {Platform.OS !== "android" && <SafeArea transparent />}
                <Logo />
            </Box>

            {!isLoading && lat && lng ? (
                <MapView
                    initialRegion={{
                        latitude: lat,
                        longitude: lng,
                        latitudeDelta: 0.03,
                        longitudeDelta: 0.03,
                    }}
                    style={{
                        width: "100%",
                        height: height,
                    }}
                    ref={mapRef}
                >
                    {businesses &&
                        businesses.map((business, i) => {
                            return (
                                <Marker
                                    tappable
                                    key={i}
                                    coordinate={{
                                        latitude: business?.lat,
                                        longitude: business?.lng,
                                    }}
                                    onPress={() =>
                                        navigation.navigate(`business`, {
                                            id: business.id,
                                        })
                                    }
                                >
                                    <>
                                        <Box p={"3px"} bg={"white"} borderRadius={40}>
                                            <Avatar
                                                source={{
                                                    uri: helpers.rebaseImage(business?.logo?.thumb),
                                                }}
                                            ></Avatar>
                                        </Box>
                                    </>
                                </Marker>
                            );
                        })}
                    <Marker
                        style={{ zIndex: 10 }}
                        coordinate={{
                            latitude: lat,
                            longitude: lng,
                        }}
                    >
                        <MaterialIcons name="account-circle" color={userMarkerColor} size={34} />
                    </Marker>
                </MapView>
            ) : (
                <Center>
                    <Spinner />
                </Center>
            )}
        </Box>
    );
}
