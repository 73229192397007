import { useColorMode, useColorModeValue, useToken } from "native-base";
import React from "react";
import { Platform, StatusBar, View } from "react-native";
import { SafeAreaView } from "react-native-safe-area-context";

export default function SafeArea({ style, transparent }) {
    const { colorMode, toggleColorMode } = useColorMode();
    const white = useToken("colors", "dark.50");
    const dark = useToken("colors", "dark.900");
    return Platform.OS == "ios" ? (
        <SafeAreaView style={transparent ? {} : { backgroundColor: colorMode == "light" ? white : dark }} />
    ) : Platform.OS == "android" ? (
        <View
            style={
                transparent
                    ? { height: StatusBar.currentHeight }
                    : { backgroundColor: colorMode == "light" ? white : dark, height: StatusBar.currentHeight }
            }
        />
    ) : (
        <View></View>
    );
}
