import { View } from "react-native";
import React from "react";
import { Box, Center, Heading, Text, VStack } from "native-base";

export default function EmptyState({ heading, text, image }) {
    return (
        <Box
            w={"100%"}
            p={8}
            _light={{ bg: "gray.100" }}
            _dark={{ bg: "dark.800" }}
            alignItems="center"
            borderRadius={"xl"}
        >
            <VStack space={4} alignItems="center">
                {image}
                {heading && (
                    <Heading size="md" my={3}>
                        {heading}
                    </Heading>
                )}
                {text && (
                    <Text textAlign={"center"} maxWidth={200}>
                        {text}
                    </Text>
                )}
            </VStack>
        </Box>
    );
}
