import { Platform } from "react-native";
import * as SecureStore from "expo-secure-store";
import AsyncStorage from "@react-native-async-storage/async-storage";
export default {
    async setItem(key, value) {
        if (Platform.OS == "web") {
            await AsyncStorage.setItem(key, value);
        } else {
            await SecureStore.setItemAsync(key, value);
        }
    },
    async getItem(key) {
        if (Platform.OS == "web") {
            return await AsyncStorage.getItem(key);
        } else {
            return await SecureStore.getItemAsync(key);
        }
    },
    async removeItem(key) {
        if (Platform.OS == "web") {
            return await AsyncStorage.removeItem(key);
        } else {
            return await SecureStore.deleteItemAsync(key);
        }
    },
};
