import { Platform, Pressable, View } from "react-native";
import React, { useContext, useEffect, useRef, useState } from "react";
import { Box, Divider, HamburgerIcon, HStack, Icon, IconButton, Menu, Toast, useColorModeValue } from "native-base";
import { Ionicons, MaterialIcons } from "@expo/vector-icons";
import SafeArea from "../SafeArea/SafeArea";
import Logo from "../Logo/Logo";
import { useLinkTo } from "@react-navigation/native";
import { TouchableOpacity } from "react-native";
import { useNavigation } from "@react-navigation/native";
import { UserContext } from "../../context/UserContext";
import api from "../../services/api";
import storage from "../../services/storage";
import { SearchContext } from "../../context/SearchContext";
import { AuthContext } from "../../context/AuthContext";
import CustomToast from "../Toast/CustomToast";
import * as Analytics from "expo-firebase-analytics";

export default function Topbar({ backgroundColor }) {
    const linkTo = useLinkTo();
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);

    const [searchFocused, setSearchFocused] = useState(false);
    const navigation = useNavigation();
    const [showBackButton, setShowBackButton] = useState(false);

    const { search, setSearch } = useContext(SearchContext);

    function logout() {
        api.auth()
            .logout()
            .then(() => {
                Toast.show({
                    placement: "top",
                    render: () => {
                        return <CustomToast text={`Logged out.`} />;
                    },
                });
                setUser(null);
                setAuth(false);
                storage.removeItem("token");
                delete axios.defaults.headers.common["Authorization"];
                Analytics.logEvent("logged_out", {
                    sender: "action",
                    user: user?.id,
                    purpose: "Logged out",
                });
                Analytics.setUserId(null);
            })
            .catch((err) => {});
    }
    return (
        <>
            <SafeArea backgroundColor={useColorModeValue("dark.50", "dark.900")}></SafeArea>
            <HStack
                px={4}
                height={60}
                justifyContent="space-between"
                alignItems="center"
                w="100%"
                bg={backgroundColor || useColorModeValue("dark.50", "dark.900")}
            >
                <HStack alignItems="center">
                    {showBackButton && (
                        <IconButton
                            onPress={() => {
                                navigation.goBack();
                            }}
                            icon={<Icon as={MaterialIcons} name="arrow-back" size="sm" />}
                        />
                    )}
                    <TouchableOpacity
                        onPress={() => {
                            setSearchFocused(false);
                            linkTo("/welcome");
                        }}
                    >
                        <Logo />
                    </TouchableOpacity>
                </HStack>
                <HStack space={2}>
                    {(Platform.OS == "web" || Platform.OS == "android") && (
                        <IconButton
                            onPress={() => {
                                linkTo("/search");
                                setSearchFocused(true);
                            }}
                            icon={<Icon as={MaterialIcons} name="search" size="sm" />}
                        />
                    )}

                    {Platform.OS == "web" && (
                        <IconButton
                            onPress={() => linkTo("/saved")}
                            icon={<Icon as={Ionicons} name="heart" size="sm" />}
                        />
                    )}

                    {user && user.business && user.business.is_verified !== 0 && (
                        <IconButton
                            onPress={() => {
                                linkTo("/manage/active");
                            }}
                            icon={<Icon as={Ionicons} name="pricetags" size="sm" />}
                        />
                    )}

                    {user && (
                        <IconButton
                            onPress={() => {
                                linkTo("/profile");
                            }}
                            icon={<Icon as={MaterialIcons} name="account-circle" size="sm" />}
                        />
                    )}

                    {!auth ? (
                        <Menu
                            padding={3}
                            placement={"bottom right"}
                            width={185}
                            marginLeft={Platform.OS == "web" ? -170 : 0}
                            borderRadius="xl"
                            onOpen={() => {
                                setSearchFocused(false);
                            }}
                            trigger={(triggerProps) => {
                                return (
                                    <IconButton
                                        accessibilityLabel="Menu"
                                        {...triggerProps}
                                        icon={<Icon as={MaterialIcons} name="menu" size="sm" />}
                                    />
                                );
                            }}
                        >
                            <Menu.Item borderRadius={4} onPress={() => navigation.navigate("businesses")}>
                                Dealio for business
                            </Menu.Item>

                            <Menu.Item
                                borderRadius={4}
                                onPress={() =>
                                    navigation.navigate("auth", {
                                        screen: "login",
                                    })
                                }
                            >
                                Log in
                            </Menu.Item>
                            <Menu.Item
                                borderRadius={4}
                                onPress={() =>
                                    navigation.navigate("auth", {
                                        screen: "register",
                                    })
                                }
                            >
                                Sign up
                            </Menu.Item>

                            <Divider marginY={4} />

                            <Menu.Item borderRadius={4} onPress={() => navigation.navigate("contact")}>
                                Contact us
                            </Menu.Item>

                            <Menu.Item borderRadius={4} onPress={() => navigation.navigate("terms")}>
                                Terms of use
                            </Menu.Item>

                            <Menu.Item borderRadius={4} onPress={() => navigation.navigate("privacy")}>
                                Privacy Policy
                            </Menu.Item>
                        </Menu>
                    ) : (
                        <Menu
                            padding={3}
                            placement={"bottom right"}
                            width={185}
                            marginLeft={Platform.OS == "web" ? -170 : 0}
                            borderRadius="xl"
                            trigger={(triggerProps) => {
                                return (
                                    <IconButton
                                        accessibilityLabel="Menu"
                                        {...triggerProps}
                                        icon={<Icon as={MaterialIcons} name="menu" size="sm" />}
                                    />
                                );
                            }}
                        >
                            {user && user.business ? (
                                user.business.is_verified !== 0 && <></>
                            ) : (
                                <Menu.Item borderRadius={4} onPress={() => navigation.navigate("onboard")}>
                                    Setup business
                                </Menu.Item>
                            )}

                            <Menu.Item borderRadius={4} onPress={() => navigation.navigate("contact")}>
                                Contact us
                            </Menu.Item>

                            <Divider marginY={4} />
                            <Menu.Item borderRadius={4} onPress={() => logout()}>
                                Log out
                            </Menu.Item>
                        </Menu>
                    )}
                </HStack>
            </HStack>
        </>
    );
}
