/**
 * Learn more about deep linking with React Navigation
 * https://reactnavigation.org/docs/deep-linking
 * https://reactnavigation.org/docs/configuring-links
 */

import * as Linking from "expo-linking";

const linking = {
    prefixes: [Linking.makeUrl("/")],
    config: {
        screens: {
            auth: {
                screens: {
                    login: "login",
                    register: "register",
                    forgotpassword: "forgotpassword",
                    resetpassword: "resetpassword",
                },
            },
            app: {
                screens: {
                    browse: {
                        screens: {
                            welcome: "welcome",
                            search: "search",
                            map: "map",
                            business: "business/:id",
                            deal: "deal/:id",
                        },
                    },
                    saved: {
                        screens: {
                            deals: "saved",
                            saveddeal: "saved/deal/:id",
                        },
                    },
                    manage: {
                        screens: {
                            home: {
                                path: "manage",
                                screens: {
                                    homeactive: "active",
                                    homeupcoming: "upcoming",
                                    homepast: "past",
                                },
                            },
                            edit: "deal/edit/:id",
                            create: "deal/create",
                        },
                    },
                    account: {
                        screens: {
                            profile: "profile",
                            editprofile: "profile/edit",
                            editbusinessprofile: "businessprofile/edit",
                            onboard: "onboard",
                        },
                    },
                },
            },
            businesses: "businesses",
            contact: "contact",
            privacy: "privacy",
            terms: "terms",
            verification: "verification",
            NotFound: "*",
        },
    },
};

export default linking;
