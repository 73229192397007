import { View, Text } from "react-native";
import React, { useEffect, useState } from "react";
import { FormControl, Input, InputGroup, InputLeftAddon } from "native-base";

export default function CurrencyInput({ label, defaultValue, isRequired, onChangeText, style }) {
    const [value, setValue] = useState(defaultValue || "");

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    function onChange(text) {
        if (!text || text.match(/^\d{1,}(\.\d{0,4})?$/)) {
            setValue(text);
            onChangeText && onChangeText(text);
        }
    }

    return (
        <FormControl isRequired={isRequired} style={style}>
            <FormControl.Label>{label}</FormControl.Label>
            <InputGroup>
                <InputLeftAddon children={"$"} />
                <Input
                    minW={100}
                    keyboardType="numeric"
                    type="number"
                    placeholder={"0.00"}
                    value={(value && value.toString()) || ""}
                    onChangeText={(text) => onChange(text)}
                />
            </InputGroup>
        </FormControl>
    );
}
