/**
 * Learn more about createBottomTabNavigator:
 * https://reactnavigation.org/docs/bottom-tab-navigator
 */

import * as React from "react";
import { useContext } from "react";
import { AuthContext } from "../context/AuthContext";
import { Ionicons, MaterialCommunityIcons } from "@expo/vector-icons";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { createStackNavigator } from "@react-navigation/stack";
import Colors from "../constants/Colors";
import HomeScreen from "../screens/HomeScreen";
import SearchScreen from "../screens/SearchScreen";
import ProfileScreen from "../screens/ProfileScreen";
import { Platform, useWindowDimensions } from "react-native";
import EditProfileScreen from "../screens/EditProfileScreen";
import WelcomeScreen from "../screens/WelcomeScreen";
import ContactScreen from "../screens/ContactScreen";
import TermsScreen from "../screens/TermsScreen";
import PrivacyScreen from "../screens/PrivacyScreen";
import SavedScreen from "../screens/SavedScreen";
import MapScreen from "../screens/MapScreen";
import DealDetailScreen from "../screens/DealDetailScreen";
import BusinessScreen from "../screens/BusinessScreen";
import ForBusinessScreen from "../screens/ForBusinessScreen";
import { UserContext } from "../context/UserContext";
import { Avatar, useColorMode, useColorModeValue, useToken } from "native-base";
import EditBusinessProfileScreen from "../screens/EditBusinessProfileScreen";
import CreateDealScreen from "../screens/CreateDealScreen";
import EditDealScreen from "../screens/EditDealScreen";
import { createNativeStackNavigator } from "@react-navigation/native-stack";
import OnboardScreen from "../screens/OnboardScreen";

const BottomTab = createBottomTabNavigator();

export default function BottomTabNavigator() {
    const window = useWindowDimensions();
    const { auth, setAuth } = useContext(AuthContext);
    const { user, setUser } = useContext(UserContext);

    const { colorMode, toggleColorMode } = useColorMode();
    return (
        <BottomTab.Navigator
            screenOptions={{
                headerShown: false,
                tabBarShowLabel: false,
                tabBarActiveTintColor: useColorModeValue("black", "white"),
                tabBarStyle: {
                    display: window.width > 960 || Platform.OS == "web" ? "none" : "flex",
                    backgroundColor:
                        colorMode == "light" ? useToken("colors", "white") : useToken("colors", "dark.800"),
                    paddingHorizontal: 40,
                    borderTopWidth: 1,
                    borderTopColor: colorMode == "light" ? useToken("colors", "dark.100") : "transparent",
                    zIndex: 9999,
                },
            }}
        >
            <BottomTab.Screen
                name="browse"
                component={BrowseNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="compass" color={color} size={27} />,
                }}
            />
            <BottomTab.Screen
                name="saved"
                component={SavedNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="heart" color={color} size={27} />,
                }}
            />
            {user?.business?.is_verified == 1 && (
                <BottomTab.Screen
                    name="manage"
                    component={ManageDealsNavigator}
                    options={{
                        tabBarIcon: ({ color }) => <TabBarIcon name="pricetags" color={color} size={23} />,
                    }}
                />
            )}

            <BottomTab.Screen
                name="account"
                component={ProfileNavigator}
                options={{
                    tabBarIcon: ({ color }) => <TabBarIcon name="person-circle" color={color} size={27} />,
                }}
            />
        </BottomTab.Navigator>
    );
}

// You can explore the built-in icon families and icons on the web at:
// https://icons.expo.fyi/
function TabBarIcon(props: { name: React.ComponentProps<typeof Ionicons>["name"]; color: string; size: number }) {
    return <Ionicons size={props.size || 26} style={{ marginBottom: -3 }} {...props} />;
}

// Each tab has its own navigation stack, you can read more about this pattern here:
// https://reactnavigation.org/docs/tab-based-navigation#a-stack-navigator-for-each-tab
const BrowseStack = createNativeStackNavigator();

function BrowseNavigator() {
    return (
        <BrowseStack.Navigator
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                },
            }}
        >
            <BrowseStack.Screen
                name="welcome"
                component={WelcomeScreen}
                options={{
                    title: "Welcome to Dealio - Find great food deals near you!",
                }}
            />

            <BrowseStack.Screen
                name="map"
                component={MapScreen}
                options={{
                    title: "Dealio - Map",
                }}
            />
            <BrowseStack.Screen name="deal" component={DealDetailScreen} />
            <BrowseStack.Screen name="business" component={BusinessScreen} />
            <BrowseStack.Screen
                name="search"
                component={SearchScreen}
                options={{ animation: "none", title: "Dealio - Search" }}
            />
        </BrowseStack.Navigator>
    );
}

const SavedStack = createNativeStackNavigator();

function SavedNavigator() {
    return (
        <SavedStack.Navigator
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                },
            }}
        >
            <SavedStack.Screen
                name="deals"
                component={SavedScreen}
                options={{
                    title: "Dealio - Saved deals",
                }}
            ></SavedStack.Screen>
            <SavedStack.Screen name="saveddeal" component={DealDetailScreen}></SavedStack.Screen>
        </SavedStack.Navigator>
    );
}

const ManageDealsStack = createNativeStackNavigator();

function ManageDealsNavigator() {
    const { auth, setAuth } = useContext(AuthContext);
    const { user, setUser } = useContext(UserContext);

    return (
        <ManageDealsStack.Navigator
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                },
            }}
        >
            {auth && user?.business?.is_verified == 1 && (
                <>
                    <ManageDealsStack.Screen
                        name="home"
                        component={HomeScreen}
                        options={{
                            title: "Dealio - Home",
                            gestureEnabled: false,
                        }}
                    />
                    <ManageDealsStack.Screen
                        options={{
                            title: "Dealio - Create deal",
                            presentation: "modal",
                        }}
                        name="create"
                        component={CreateDealScreen}
                    />
                    <ManageDealsStack.Screen
                        name="edit"
                        component={EditDealScreen}
                        options={{
                            title: "Dealio - Edit deal",
                        }}
                    />
                </>
            )}
        </ManageDealsStack.Navigator>
    );
}

const ProfileStack = createNativeStackNavigator();

function ProfileNavigator() {
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);
    return (
        <ProfileStack.Navigator
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                },
            }}
        >
            <ProfileStack.Screen
                name="profile"
                component={ProfileScreen}
                options={{
                    title: "Dealio - Profile",
                }}
            />

            {user && auth && (
                <>
                    <ProfileStack.Screen
                        name="editprofile"
                        component={EditProfileScreen}
                        options={{
                            title: "Dealio - Edit profile",
                        }}
                    />

                    <ProfileStack.Screen
                        name="editbusinessprofile"
                        component={EditBusinessProfileScreen}
                        options={{
                            title: "Dealio - Edit business profile",
                        }}
                    />
                </>
            )}

            {auth && user && !user.business && (
                <ProfileStack.Screen
                    name="onboard"
                    component={OnboardScreen}
                    options={{
                        title: "Dealio - Setup a business",
                    }}
                />
            )}
        </ProfileStack.Navigator>
    );
}
