import React, { useEffect, useState } from "react";
import { Avatar, Box, Button, useToken } from "native-base";
import ImageLoader from "../Loader/ImageLoader";
import * as ImagePicker from "expo-image-picker";
import { Platform } from "react-native";
import { MaterialCommunityIcons, MaterialIcons } from "@expo/vector-icons";

export default function LogoUploader({ image, onPress, onChange }) {
    const [previewImage, setPreviewImage] = useState(null);
    const icon = useToken("colors", "gray.300");

    const fetchImageFromUri = async (uri) => {
        const response = await fetch(uri);
        const blob = await response.blob();
        return blob;
    };

    async function chooseImage() {
        let result = await ImagePicker.launchImageLibraryAsync({
            mediaTypes: ImagePicker.MediaTypeOptions.All,
            quality: 1,
        });
        if (result.cancelled) {
            return;
        }
        // ImagePicker saves the taken photo to disk and returns a local URI to it
        let localUri = result.uri;
        let filename = localUri.split("/").pop();

        // Infer the type of the image
        let match = /\.(\w+)$/.exec(filename);
        let type = match ? `image/${match[1]}` : `image`;

        setPreviewImage(localUri);
        const img = await fetchImageFromUri(localUri);
        //onChange({ uri: localUri, name: filename, type });
        onChange({ blob: img, uri: localUri, name: filename, type });
    }

    return (
        <Box alignItems={"center"} justifyContent="center">
            <Box width={120} height={120} borderRadius={100} overflow="hidden" bg={"gray.200"}>
                <ImageLoader
                    source={{
                        uri: previewImage || image,
                    }}
                    style={{
                        width: 120,
                        height: 120,
                        alignItems: "center",
                        justifyContent: "center",
                        position: "absolute",
                    }}
                    borderRadius={100}
                ></ImageLoader>
            </Box>

            <Button variant={"link"} onPress={chooseImage}>
                {image ? "Change" : "Choose logo"}
            </Button>
        </Box>
    );
}
