import React, { useContext, useEffect, useState } from "react";
import { Avatar, Badge, Box, Divider, Heading, HStack, Image, VStack } from "native-base";
import { Platform, Pressable, TouchableOpacity } from "react-native";
import { useLinkTo } from "@react-navigation/native";
import ImageLoader from "../Loader/ImageLoader";
import helpers from "../../utils/helpers";
import { UserLocationContext } from "../../context/UserLocationContext";

export default function DealCard({
    id,
    logo,
    image,
    title,
    original_price,
    deal_price,
    lat,
    lng,
    starting,
    ending,
    repeat,
    userLocation,
    link,
}) {
    const LinkTo = useLinkTo();

    const [isPressed, setIsPressed] = useState(false);
    const [timeStatus, setTimeStatus] = useState("");
    function updateTimeStatus() {
        starting && ending && repeat && setTimeStatus(helpers.getReadableTimeStatus(starting, ending, repeat));
    }

    useEffect(() => {
        updateTimeStatus();
    }, []);

    setInterval(() => {
        updateTimeStatus();
    }, 60000);

    return (
        <Pressable
            onPress={() => LinkTo(link)}
            style={({ pressed }) => [
                {
                    backgroundColor: pressed ? setIsPressed(true) : setIsPressed(false),
                },
            ]}
        >
            <Box
                bg="white"
                width={"100%"}
                shadow={Platform.OS !== "android" && "5"}
                style={{ elevation: 5 }}
                borderRadius={"xl"}
                _light={{ bg: "dark.200" }}
                _dark={{ bg: "dark.700" }}
                opacity={isPressed ? 0.5 : 1}
            >
                <HStack borderRadius={"xl"} overflow={"hidden"}>
                    <ImageLoader
                        source={{
                            uri: image,
                        }}
                        style={{
                            width: "38%",
                        }}
                    />

                    <Avatar
                        size="md"
                        position={"absolute"}
                        left={2}
                        top={2}
                        source={{
                            uri: logo,
                        }}
                    />

                    <VStack
                        width={"62%"}
                        paddingY={4}
                        paddingX={4}
                        space={3}
                        justifyContent={"center"}
                        _light={{ bg: "dark.50" }}
                        _dark={{ bg: "dark.800" }}
                    >
                        <HStack space={4}>
                            {userLocation && lat && lng && (
                                <Heading size={"xs"} color={"muted.400"}>
                                    {helpers.getReadableDistanceFromLatLon(
                                        userLocation.lat,
                                        userLocation.lng,
                                        lat,
                                        lng
                                    )}
                                </Heading>
                            )}

                            <Heading size={"xs"} color={"muted.400"}>
                                {timeStatus}
                            </Heading>
                        </HStack>
                        <Box height="10">
                            <Heading
                                size="sm"
                                _light={{ color: "black" }}
                                _dark={{ color: "white" }}
                                isTruncated
                                numberOfLines={2}
                            >
                                {title}
                            </Heading>
                        </Box>

                        {original_price && deal_price && (
                            <HStack space={2} alignItems={"center"}>
                                <Heading size={"xs"} color={"gray.400"} strikeThrough>
                                    {helpers.intToPrice(original_price)}
                                </Heading>
                                <Heading size={"sm"} color={"primary.500"}>
                                    {helpers.intToPrice(deal_price)}
                                </Heading>
                                <Badge colorScheme="success">
                                    {`${helpers.percentageChange(original_price, deal_price)} off`}
                                </Badge>
                            </HStack>
                        )}
                    </VStack>
                </HStack>
            </Box>
        </Pressable>
    );
}
