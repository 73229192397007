import {
    Avatar,
    Badge,
    Box,
    Button,
    Fade,
    Heading,
    HStack,
    Slide,
    SlideFade,
    Text,
    useColorModeValue,
    useToken,
    VStack,
} from "native-base";
import { ScrollView } from "react-native-gesture-handler";
import CountDown from "react-native-countdown-component";
import { useContext, useEffect, useState } from "react";
import Container from "../components/Container/Container";
import ImageLoader from "../components/Loader/ImageLoader";
import { Linking, Platform, TouchableOpacity } from "react-native";
import helpers from "../utils/helpers";
import api from "../services/api";
import ActionBar from "../components/Topbar/ActionBar";
import * as MailComposer from "expo-mail-composer";
import SaveButton from "../components/Button/SaveButton";
import SafeArea from "../components/SafeArea/SafeArea";
import { UserContext } from "../context/UserContext";
import * as Analytics from "expo-firebase-analytics";

export default function DealDetailScreen({ route, navigation }) {
    const { user, setUser } = useContext(UserContext);
    const [deal, setDeal] = useState(null);

    const borderRadius = useToken("radii", "xl");
    const countDownTextColor = useColorModeValue("#333", "#fff");

    const [until, setUntil] = useState({
        seconds: 0,
        isActive: true,
        ended: false,
    });

    const now = new Date();

    useEffect(() => {
        getDeal();
    }, []);

    useEffect(() => {
        deal && setUntil(helpers.getCountDown(deal?.starting, deal?.ending, deal?.repeat));
    }, [deal]);

    function getDeal() {
        let { id } = route.params;

        api.deal()
            .getOnePublic({ id: id })
            .then((res) => {
                Analytics.logEvent("view_deal", {
                    sender: "action",
                    user: user?.id,
                    dealId: res.data.body?.id,
                    screen: "Deal",
                    purpose: "Viewed a deal",
                });
                navigation.setOptions({ title: "Dealio - " + res.data.body.title });
                var dealData = helpers.convertUTCDateTimesToLocal(res.data.body, ["starting", "ending"]);
                setDeal(dealData);
            });
    }

    function save() {
        let { id } = route.params;
        api.save()
            .create({ deal_id: id })
            .then(() => {
                Analytics.logEvent("save_deal", {
                    sender: "action",
                    user: user?.id,
                    screen: "Deal",
                    purpose: "Saved a deal",
                });
            });
    }

    function unsave() {
        let { id } = route.params;
        api.save()
            .delete({ deal_id: id })
            .then(() => {});
    }

    function openDealInMaps() {
        if (deal) {
            var url = `https://www.google.com/maps/place/${deal?.business?.address}`;
            Linking.openURL(url);
            Analytics.logEvent("opened_deal_in_maps", {
                sender: "action",
                user: user?.id,
                screen: document.title,
                purpose: "Opened deal in maps",
            });
        }
    }

    return (
        <>
            <ScrollView showsVerticalScrollIndicator={false}>
                <SafeArea />
                <Container maxWidth={600}>
                    <VStack padding={4} space={5}>
                        <Fade in={deal}>
                            <VStack space={5}>
                                <HStack alignItems="center" justifyContent="space-between">
                                    <ActionBar back backOnPress={() => navigation.goBack()} />
                                    <Box pt={2}>
                                        <SaveButton
                                            enabled
                                            isSaved={deal?.saved}
                                            onPress={(state) => {
                                                state ? save() : unsave();
                                            }}
                                        />
                                    </Box>
                                </HStack>

                                <Box _light={{ bg: "dark.100" }} _dark={{ bg: "dark.800" }} borderRadius={borderRadius}>
                                    <ImageLoader
                                        source={{
                                            uri: helpers.rebaseImage(deal?.image?.thumb),
                                        }}
                                        style={{
                                            borderRadius: borderRadius,
                                            width: "100%",
                                            height: 250,
                                        }}
                                    />

                                    <TouchableOpacity
                                        onPress={() =>
                                            navigation.navigate(`business`, {
                                                id: deal?.business?.id,
                                            })
                                        }
                                        style={{
                                            position: "absolute",
                                            right: -8,
                                            top: -8,
                                        }}
                                    >
                                        <Avatar
                                            size="lg"
                                            source={{
                                                uri: deal && helpers.rebaseImage(deal.business?.logo?.thumb),
                                            }}
                                        />
                                    </TouchableOpacity>
                                </Box>

                                <Text color="gray.500">
                                    {helpers.formatDateTimeRange(deal?.starting, deal?.ending, deal?.repeat)}
                                </Text>

                                <Heading size="xl" maxW={{ sm: 300, md: 500 }}>
                                    {deal?.title}
                                </Heading>

                                {deal?.original_price && deal?.deal_price && (
                                    <HStack space={4} alignItems={"center"}>
                                        <Heading size={"xs"} color={"gray.400"} strikeThrough>
                                            {helpers.intToPrice(deal?.original_price)}
                                        </Heading>
                                        <Heading size={"sm"} color={"primary.500"}>
                                            {helpers.intToPrice(deal?.deal_price)}
                                        </Heading>
                                        <Badge colorScheme="success">
                                            {`${helpers.percentageChange(deal?.original_price, deal?.deal_price)} off`}
                                        </Badge>
                                    </HStack>
                                )}

                                <Text>{deal?.description}</Text>

                                <HStack space={4} justifyContent={"space-between"}>
                                    <Button onPress={() => openDealInMaps()}>Open in maps</Button>
                                    <Button
                                        colorScheme={"secondary"}
                                        variant="ghost"
                                        onPress={() => {
                                            MailComposer.composeAsync({
                                                recipients: ["contact@dealioit.com"],
                                                subject: "Dealio user report",
                                                body: `Reference link: \n ${
                                                    window?.location?.href || `https://dealioit.com/deal/${deal?.id}`
                                                } \n \n Hi, I would like to report this deal because: `,
                                            });
                                            Analytics.logEvent("pressed_report_deal", {
                                                sender: "action",
                                                user: user?.id,
                                                screen: document?.title,
                                                purpose: "Pressed report button on a deal",
                                            });
                                        }}
                                    >
                                        Report
                                    </Button>
                                </HStack>

                                <Box height="64px"></Box>
                            </VStack>
                        </Fade>
                    </VStack>
                </Container>
            </ScrollView>

            <Slide in={deal !== null} placement="bottom" delay={0} duration={800}>
                <Box
                    px={4}
                    py={1}
                    borderTopWidth={1}
                    position="absolute"
                    width={"100%"}
                    bottom={Platform.OS == "web" ? 0 : 49}
                    zIndex={10}
                    _light={{ borderTopColor: "dark.200", bg: "white" }}
                    _dark={{ borderTopColor: "dark.800", bg: "dark.800" }}
                >
                    {deal?.ending && until?.seconds > 0 ? (
                        <HStack alignItems="center" justifyContent="space-between">
                            <Heading size="sm">
                                {until?.ended ? "Ended" : until?.isActive ? "Ending" : "Starting"}
                            </Heading>

                            <CountDown
                                size={24}
                                until={until?.seconds}
                                digitStyle={{
                                    borderRadius: 16,
                                    height: 32,
                                    paddingTop: 5,
                                }}
                                digitTxtStyle={{
                                    color: countDownTextColor,
                                }}
                                timeLabelStyle={{
                                    fontSize: 10,
                                    color: countDownTextColor,
                                    marginBottom: 6,
                                }}
                                separatorStyle={{
                                    marginBottom: 10,
                                }}
                                timeToShow={["D", "H", "M", "S"]}
                                timeLabels={{ d: "days", h: "hours", m: "mins", s: "secs" }}
                            />
                        </HStack>
                    ) : (
                        <Heading size="sm" py={3}>
                            Ended{" "}
                        </Heading>
                    )}
                </Box>
            </Slide>
        </>
    );
}
