import React, { createRef, useState } from "react";
import { FormControl, HStack, useColorModeValue, useToken } from "native-base";
import helpers from "../../utils/helpers";

export default function DateTimeInput({ label, isRequired, defaultValue, onChangeText }) {
    const [date, setDate] = useState(defaultValue || new Date());

    const webDateInput = createRef(null);
    const webTimeInput = createRef(null);

    const onChangeWeb = (event, selectedDate) => {
        const dateVals = webDateInput.current.value.split("-");
        const timeVals = webTimeInput.current.value.split(":");
        const datetime = new Date(dateVals[0], dateVals[1] - 1, dateVals[2], timeVals[0], timeVals[1], 0, 0);
        setDate(datetime);
        onChangeText && onChangeText(helpers.formatDateToUTCDateTime(datetime));
    };

    const inputStyle = {
        padding: 14,
        borderWidth: 1,
        fontWeight: 600,
        borderStyle: "solid",
        borderRadius: 5,
        borderColor: useToken("colors", useColorModeValue("dark.200", "dark.800")),
        backgroundColor: useToken("colors", useColorModeValue("white", "dark.800")),
        color: useToken("colors", useColorModeValue("dark.800", "white")),
        fontFamily: "sans-serif",
        fontSize: 14,
        marginRight: 10,
    };

    return (
        <FormControl isRequired={isRequired}>
            <FormControl.Label>{label}</FormControl.Label>

            <HStack>
                {React.createElement("input", {
                    ref: webDateInput,
                    style: inputStyle,
                    type: "date",
                    value:
                        date.getFullYear() +
                        "-" +
                        helpers.pad(date.getMonth() + 1, 2) +
                        "-" +
                        helpers.pad(date.getDate(), 2),
                    onInput: onChangeWeb,
                })}
                {React.createElement("input", {
                    ref: webTimeInput,
                    style: inputStyle,
                    type: "time",
                    value: helpers.pad(date.getHours(), 2) + ":" + helpers.pad(date.getMinutes(), 2),
                    onInput: onChangeWeb,
                })}
            </HStack>
        </FormControl>
    );
}
