import { Ionicons } from "@expo/vector-icons";
import { Heading, HStack, Icon, IconButton, Input, VStack } from "native-base";
import { useContext, useEffect, useRef, useState } from "react";
import { ScrollView } from "react-native-gesture-handler";
import Container from "../components/Container/Container";
import EmptyState from "../components/Empty/EmptyState";
import DealsList from "../components/List/DealsList";
import { UserLocationContext } from "../context/UserLocationContext";
import api from "../services/api";
import location from "../services/location";
import helpers from "../utils/helpers";

export default function SearchScreen({ navigation }) {
    const [deals, setDeals] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const searchInput = useRef(null);

    const [search, setSearch] = useState("");
    const { userLocation, setUserLocation } = useContext(UserLocationContext);

    useEffect(() => {
        !userLocation && getUserLocation();
        searchInput && searchInput.current.blur();
        searchInput &&
            setTimeout(() => {
                searchInput.current.focus();
            }, 100);
    }, []);

    async function getUserLocation() {
        setIsLoading(true);
        const coords = await location.requestUserLocation();
        if (!coords) {
            console.log("Location denied");
        } else {
            setUserLocation(coords);
            getAllDeals(coords);
        }
    }
    useEffect(() => {
        !deals && userLocation && getAllDeals(userLocation);
    }, [userLocation]);

    function getAllDeals(coords) {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        setIsLoading(true);
        api.deal()
            .getAll({ lat: coords.lat, lng: coords.lng, timezone: timezone })
            .then((res) => {
                setIsLoading(false);
                const deals = helpers.convertUTCDateTimesToLocal(res.data.body, ["starting", "ending"]);
                setDeals(deals);
            });
    }

    function searchFilter(deal) {
        return (
            deal?.title?.toLowerCase().includes(search.toLowerCase().trim()) ||
            deal?.business?.business_name?.toLowerCase().includes(search.toLowerCase().trim())
        );
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <Container maxWidth={600}>
                <HStack alignItems={"center"} px={4} space={2}>
                    <Input
                        minW={200}
                        ref={searchInput}
                        onChangeText={(value) => setSearch(value)}
                        placeholder={"Search deals"}
                        flex={1}
                    ></Input>
                    <IconButton
                        icon={<Icon as={Ionicons} name="close" size="sm" />}
                        onPress={() => {
                            navigation.canGoBack() && navigation.goBack();
                            setSearch("");
                        }}
                    />
                </HStack>

                <VStack padding={4} space={5}>
                    {search !== "" && <Heading>Results for {search}</Heading>}

                    <VStack space={5}>
                        {search !== "" ? (
                            <DealsList
                                deals={search !== "" && deals?.filter((deal) => searchFilter(deal))}
                                isLoading={isLoading}
                                emptyText={search == "" ? <></> : "We couldn't find what you're looking for, sorry."}
                            />
                        ) : (
                            <EmptyState text="Search for deals by name or business name" />
                        )}
                    </VStack>
                </VStack>
            </Container>
        </ScrollView>
    );
}
