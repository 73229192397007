import { View, Text } from "react-native";
import React from "react";
import { Box, HStack, Skeleton, VStack } from "native-base";

export default function DealListSkeleton({ responsiveColumns }) {
    return (
        <>
            {Array.from({
                length: 6,
            }).map((value, i) => (
                <Box
                    key={i}
                    pb={4}
                    pr={{ md: 4 }}
                    width={{
                        sm: "100%",
                        md: responsiveColumns ? "50%" : "100%",
                    }}
                >
                    <Skeleton h="130" w={"100%"} borderRadius={"xl"} />
                </Box>
            ))}
        </>
    );
}
