import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import { Alert, Box, Button, FormControl, Heading, HStack, Input, Text, Toast, VStack } from "native-base";
import CustomToast from "../components/Toast/CustomToast";
import Container from "../components/Container/Container";
import BasicModal from "../components/Modal/BasicModal";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import ActionBar from "../components/Topbar/ActionBar";
import SafeArea from "../components/SafeArea/SafeArea";
import { StatusBar } from "expo-status-bar";
import * as Analytics from "expo-firebase-analytics";

export default function EditProfileScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);

    const [formData, setData] = useState({});
    const [errors, setErrors] = useState({});

    const [showUserDeleteModal, setShowUserDeleteModal] = useState(false);
    const [deletingUser, setDeletingUser] = useState(false);

    const [passwordResetSent, setPasswordResetSent] = useState(false);

    useEffect(() => {
        getAuthUser();
    }, []);

    function getAuthUser() {
        return api
            .auth()
            .user()
            .then((res) => {
                setUser(res.data.body);
            });
    }

    function sendPasswordResetEmail() {
        api.auth()
            .sendPasswordResetLink({ email: user?.email })
            .then((res) => {
                Analytics.logEvent("send_password_reset_email", {
                    sender: "action",
                    user: user?.id,
                    screen: `Edit profile`,
                    purpose: "Reset user password",
                });
                setErrors({});
                setPasswordResetSent(true);
            })
            .catch((err) => {
                setErrors(err.response?.data?.errors);
            });
    }

    function deleteUser() {
        setDeletingUser(true);
        api.user()
            .delete({ id: user?.id })
            .then(() => {
                Analytics.logEvent("delete_user_account", {
                    sender: "action",
                    user: user?.id,
                    screen: `Edit profile`,
                    purpose: "Delete user account",
                });
                getAuthUser();
                setDeletingUser(false);
                setShowUserDeleteModal(false);
                navigation.navigate("welcome");
                Toast.show({
                    placement: "top",
                    render: () => {
                        return <CustomToast text={`Your account has been deleted.`} />;
                    },
                });
            })
            .catch((err) => {
                setDeletingUser(false);
                setErrors(err.response.data.errors);
            });
    }

    return (
        <>
            <StatusBar />

            <Container maxWidth={600}>
                <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                    <SafeArea transparent />
                    <Box p={4}>
                        <ActionBar back backOnPress={() => navigation.goBack()} />
                    </Box>
                    <VStack space={8} p={4}>
                        <FormControl>
                            <FormControl.Label>Email</FormControl.Label>
                            <Input isDisabled type="text" defaultValue={user?.email} />
                            {errors && "business_name" in errors && (
                                <Text color={"error.500"}>{errors.business_name}</Text>
                            )}
                        </FormControl>

                        <VStack space={2} alignItems="flex-start">
                            <Heading size="sm">Password</Heading>
                            <Text>Forgot your password? Send a reset link to your account email.</Text>
                            {passwordResetSent ? (
                                <Alert status={"success"} width="100%">
                                    An email has been sent to {user?.email} with a link to reset your password.
                                </Alert>
                            ) : (
                                <Button onPress={() => sendPasswordResetEmail()}>Send reset email</Button>
                            )}
                        </VStack>

                        <VStack space={2} alignItems="flex-start">
                            <Heading size="sm">Delete account</Heading>
                            <Text>Delete your user account as well as your business and deals.</Text>
                            <Button onPress={() => setShowUserDeleteModal(true)} variant="outline" colorScheme="error">
                                Delete user account
                            </Button>
                        </VStack>
                    </VStack>
                </KeyboardAwareScrollView>
            </Container>

            <BasicModal
                header={`Delete account`}
                show={showUserDeleteModal}
                children={
                    <Text>
                        Are you sure you want to delete your user account? This will also delete your business, its
                        deals and cannot be undone.
                    </Text>
                }
                buttons={
                    <HStack space={2}>
                        <Button variant="ghost" onPress={() => setShowUserDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            onPress={() => deleteUser()}
                            isLoading={deletingUser}
                            isLoadingText={"Deleting account"}
                        >
                            Confirm
                        </Button>
                    </HStack>
                }
            ></BasicModal>
        </>
    );
}
