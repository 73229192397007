import * as Location from "expo-location";

export default {
    requestUserLocation() {
        return (async () => {
            let { status } = await Location.requestForegroundPermissionsAsync();

            if (status !== "granted") {
                return false;
            }

            let location = await Location.getCurrentPositionAsync({
                accuracy: Location.Accuracy.Low,
            });

            return {
                lat: location.coords.latitude,
                lng: location.coords.longitude,
            };
        })();
    },
    async geocodeAddress(address) {
        return await Location.geocodeAsync(address, null);
    },
    async reverseGeocodeAsync(location) {
        return await Location.reverseGeocodeAsync(location, null);
    },
};
