import React from "react";
import { Alert, Box, HStack, Text, VStack } from "native-base";

export default function PendingVerificationAlert({ business }) {
    return (
        <Alert status="info" colorScheme="warning" mb={3}>
            <VStack space={2} flexShrink={1} w="100%">
                <HStack flexShrink={1} space={2} alignItems="center" justifyContent="space-between">
                    <HStack flexShrink={1} space={2} alignItems="center">
                        <Alert.Icon />
                        <Text fontSize="md" fontWeight="medium" color="coolGray.800" pr={4}>
                            Your business "{business}" is pending verification.
                        </Text>
                    </HStack>
                </HStack>
                <Box
                    pl="6"
                    _text={{
                        color: "coolGray.600",
                    }}
                >
                    We'll be in touch with you soon to verify your business.
                </Box>
            </VStack>
        </Alert>
    );
}
