import { View, Text, FlatList, Platform } from "react-native";
import React, { useContext, useEffect } from "react";
import DealListSkeleton from "../Skeleton/DealListSkeleton";
import EmptyState from "../Empty/EmptyState";
import DealCard from "../Card/DealCard";
import { Box, Button, Fade, Icon, VStack } from "native-base";
import { UserLocationContext } from "../../context/UserLocationContext";
import helpers from "../../utils/helpers";
import AnimatedLottieView from "lottie-react-native";
import Emoji from "react-native-emoji";

export default function DealsList({
    linkToEdit,
    link,
    deals,
    logo,
    isLoading,
    responsiveColumns,
    emptyHeading,
    emptyText,
}) {
    const { userLocation, setUserLocation } = useContext(UserLocationContext);

    return (
        <Box
            width="100%"
            justifyContent={{ md: "space-between" }}
            alignItems={{ md: "flex-start" }}
            flexDirection={{ md: "row" }}
            flex={{ md: 1 }}
            flexWrap={{ md: "wrap" }}
        >
            {!isLoading || deals ? (
                deals && deals.length > 0 ? (
                    deals.map((deal, i) => (
                        <Box
                            key={deal?.id}
                            pb={4}
                            pr={{ md: 4 }}
                            width={{
                                sm: "100%",
                                md: responsiveColumns ? "50%" : "100%",
                            }}
                        >
                            <Fade in={deal} width={"100%"}>
                                <DealCard
                                    id={deal.id}
                                    link={
                                        link
                                            ? link + `/${deal.id}`
                                            : linkToEdit
                                            ? `/deal/edit/${deal.id}`
                                            : `/deal/${deal.id}`
                                    }
                                    logo={logo || helpers.rebaseImage(deal.business?.logo?.thumb)}
                                    image={helpers.rebaseImage(deal.image?.thumb)}
                                    title={deal.title}
                                    original_price={deal.original_price}
                                    deal_price={deal.deal_price}
                                    lat={deal?.business?.lat}
                                    lng={deal?.business?.lng}
                                    ending={deal?.ending}
                                    starting={deal?.starting}
                                    repeat={deal?.repeat}
                                    userLocation={userLocation}
                                />
                            </Fade>
                        </Box>
                    ))
                ) : (
                    <EmptyState
                        heading={emptyHeading}
                        text={emptyText}
                        image={
                            Platform.OS !== "web" ? (
                                <AnimatedLottieView
                                    autoPlay
                                    loop
                                    style={{
                                        width: 80,
                                        height: 80,
                                    }}
                                    source={require("../../assets/animation/no-result.json")}
                                />
                            ) : (
                                <Emoji name="shrug" style={{ fontSize: 60 }} />
                            )
                        }
                    />
                )
            ) : (
                <DealListSkeleton responsiveColumns={responsiveColumns} />
            )}
        </Box>
    );
}
