import { View, Text } from "react-native";
import React, { useEffect, useRef, useState } from "react";
import { FormControl, Input } from "native-base";
import AutocompleteDropdown from "./AutocompleteDropdown";

export default function Autocomplete({ label, options, onChangeText, onSelect, defaultValue, required, isDisabled }) {
    const [isFocused, setIsFocused] = useState(false);
    const [value, setValue] = useState(defaultValue || "");

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    const textInput = useRef(null);

    return (
        <FormControl isRequired={required}>
            <FormControl.Label>{label}</FormControl.Label>
            <Input
                type="text"
                ref={textInput}
                autoCompleteType="off"
                value={value}
                onChangeText={(val) => {
                    setValue(val);
                    onChangeText(val);
                    val == "" ? setIsFocused(false) : setIsFocused(true);
                }}
                isDisabled={isDisabled}
            />
            {isFocused && (
                <AutocompleteDropdown
                    onPress={(row) => {
                        onSelect(row);
                        setValue(row.label);
                        setIsFocused(false);
                    }}
                    items={options}
                />
            )}
        </FormControl>
    );
}
