import axios from "axios";
const WEB_URL = process.env.NODE_ENV === "production" ? "https://backend.dealioit.com" : "http://10.0.0.11:80";
const BASE_URL = `${WEB_URL}/api`;

export default {
    auth(url = BASE_URL) {
        return {
            user: () => axios.get(`${url}/authuser`),
            login: (toPost) => axios.post(`${url}/login`, toPost),
            register: (toPost) => axios.post(`${url}/register`, toPost),
            logout: (toPost) => axios.post(`${url}/logout`, toPost),
            sendPasswordResetLink: (toPost) => axios.post(`${url}/forgot-password`, toPost),
            resetPassword: (toPost) => axios.post(`${url}/reset-password`, toPost),
        };
    },
    user(url = BASE_URL + "/users") {
        return {
            delete: ({ id }) => axios.delete(`${url}/${id}`),
        };
    },
    business(url = BASE_URL + "/businesses") {
        return {
            getActiveDeals: ({ id, timezone }) =>
                axios.get(`${url}/${id}/deals/active`, { params: { timezone: timezone } }),
            getDeals: ({ id }) => axios.get(`${url}/${id}/deals`),
            create: (fd) =>
                axios.post(`${url}`, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    transformRequest: (data, headers) => {
                        return fd;
                    },
                }),
            getOne: ({ id }) => axios.get(`${url}/${id}`),
            update: (toUpdate) => axios.patch(`${url}/${toUpdate.id}`, toUpdate),
            delete: ({ id }) => axios.delete(`${url}/${id}`),
            uploadLogo: (id, fd) =>
                axios.post(`${url}/${id}/logo`, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    transformRequest: (data, headers) => {
                        return fd;
                    },
                }),
        };
    },
    deal(url = BASE_URL + "/deals") {
        return {
            getAll: (latLng) => axios.get(url, { params: latLng }),
            getOne: ({ id }) => axios.get(`${url}/${id}`),
            getOnePublic: ({ id }) => axios.get(`${BASE_URL}/public/deals/${id}`),
            create: ({ business_id }, fd) =>
                axios.post(`${BASE_URL}/businesses/${business_id}/deals`, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                    transformRequest: (data, headers) => {
                        return fd;
                    },
                }),
            update: (toUpdate) => axios.patch(`${url}/${toUpdate.id}`, toUpdate),
            delete: ({ id }) => axios.delete(`${url}/${id}`),
            uploadImage: (id, fd) =>
                axios.post(`${url}/${id}/image`, fd, {
                    headers: {
                        "Content-Type": "multipart/form-data;",
                    },
                    transformRequest: (data, headers) => {
                        return fd;
                    },
                }),
        };
    },
    save(url = `${BASE_URL}/saves`) {
        return {
            getAll: () => axios.get(`${url}`),
            create: (toPost) => axios.post(`${url}/${toPost.deal_id}`, toPost),
            delete: ({ deal_id }) => axios.delete(`${url}/deals/${deal_id}`),
        };
    },
};
