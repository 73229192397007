import { Center, Fade, Heading, VStack } from "native-base";
import { ScrollView } from "react-native-gesture-handler";
import { useContext, useEffect, useState } from "react";
import Container from "../components/Container/Container";
import ActionBar from "../components/Topbar/ActionBar";
import BusinessHeader from "../components/Header/BusinessHeader";
import api from "../services/api";
import DealsList from "../components/List/DealsList";
import helpers from "../utils/helpers";
import { UserLocationContext } from "../context/UserLocationContext";
import * as Localization from "expo-localization";
import "intl";
import SafeArea from "../components/SafeArea/SafeArea";

export default function BusinessScreen({ route, navigation }) {
    const { userLocation, setUserLocation } = useContext(UserLocationContext);

    const [business, setBusiness] = useState(null);
    const [deals, setDeals] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getBusinessDeals();
    }, []);

    function getBusinessDeals() {
        let { id } = route.params;
        const timezone = Localization.timezone;

        setIsLoading(true);
        api.business()
            .getActiveDeals({ id: id, timezone: timezone })
            .then((res) => {
                navigation.setOptions({ title: "Dealio - " + res.data.body.business?.business_name });
                setBusiness(res.data?.body?.business);
                var deals =
                    res.data?.body?.deals &&
                    helpers.convertUTCDateTimesToLocal(res.data.body?.deals, ["starting", "ending"]);
                setDeals(deals);
                setIsLoading(false);
            });
    }

    return (
        <ScrollView showsVerticalScrollIndicator={false}>
            <Container>
                <SafeArea transparent />
                <Fade in={business}>
                    <VStack padding={4} space={2}>
                        <ActionBar back backOnPress={() => navigation.goBack()} />
                        <BusinessHeader
                            image={business && helpers.rebaseImage(business.logo?.thumb)}
                            businessName={business?.business_name}
                            shopName={business?.shop_name}
                        />
                        {userLocation && business && (
                            <Center>
                                <Heading size={"xs"} color={"gray.400"}>
                                    {helpers.getReadableDistanceFromLatLon(
                                        userLocation?.lat,
                                        userLocation?.lng,
                                        business?.lat,
                                        business?.lng
                                    )}{" "}
                                    away
                                </Heading>
                            </Center>
                        )}
                    </VStack>
                </Fade>
                <Fade in={deals}>
                    <VStack padding={4} space={4}>
                        <DealsList
                            deals={deals}
                            logo={business && helpers.rebaseImage(business?.logo?.thumb)}
                            isLoading={isLoading}
                            responsiveColumns
                            emptyHeading={"No deal!"}
                            emptyText={"This business has no active deals right now."}
                        />
                    </VStack>
                </Fade>
            </Container>
        </ScrollView>
    );
}
