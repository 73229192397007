import { View, Text, Platform } from "react-native";
import React from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { Box, Icon, IconButton } from "native-base";
import SaveButton from "../Button/SaveButton";

export default function ActionBar({
    back,
    backOnPress,
    close,
    closeOnPress,
    saveButton,
    isSaved,
    onSavePress,
    padding,
}) {
    return Platform.OS !== "web" ? (
        <Box p={padding} pt={2} flexDir="row" justifyContent={"space-between"}>
            {back && backOnPress && (
                <IconButton
                    alignSelf={"flex-start"}
                    onPress={backOnPress}
                    icon={<Icon as={MaterialIcons} name="arrow-back" size="sm" />}
                />
            )}
            {saveButton && onSavePress && (
                <Box ml={"auto"} alignSelf="flex-end">
                    <SaveButton enabled onPress={onSavePress} isSaved={isSaved || false} />
                </Box>
            )}
            {close && closeOnPress && <Box></Box>}
            {close && closeOnPress && (
                <IconButton
                    alignSelf="flex-end"
                    onPress={closeOnPress}
                    icon={<Icon as={MaterialIcons} name="close" size="sm" />}
                />
            )}
        </Box>
    ) : (
        <></>
    );
}
