import axios from "axios";
import { Platform } from "react-native";
import storage from "./storage";
import * as Device from "expo-device";

export default {
    async userIsLoggedIn() {
        return await storage.getItem("token").then((token) => {
            if (token !== null) {
                return true;
            } else {
                return false;
            }
        });
    },
    getDeviceName() {
        if (Platform.OS == "web") {
            return Device.osName + " " + Device.osVersion;
        } else {
            return Device.deviceName;
        }
    },
};
