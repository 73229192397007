import { useContext, useEffect, useState } from "react";
import { MaterialIcons } from "@expo/vector-icons";
import { Box, Button, Heading, HStack, ScrollView, Toast, useColorModeValue, VStack } from "native-base";
import Container from "../components/Container/Container";
import { UserContext } from "../context/UserContext";
import api from "../services/api";
import TabNavigator from "../components/Tabs/TabNavigator";
import CustomToast from "../components/Toast/CustomToast";
import helpers from "../utils/helpers";
import { StatusBar } from "expo-status-bar";
import DealsList from "../components/List/DealsList";
import { AuthContext } from "../context/AuthContext";
import SafeArea from "../components/SafeArea/SafeArea";

export default function HomeScreen({ route, navigation }) {
    const { user, setUser } = useContext(UserContext);
    const [deals, setDeals] = useState(null);

    const [logo, setLogo] = useState(null);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        getDeals();
    }, []);

    useEffect(() => {
        if (route.params) {
            if (route.params.refetch) {
                getDeals();
                route.params.refetch = false;
            }
            if (route.params.createdDeal) {
                const created = route.params.createdDeal;
                Toast.show({
                    placement: "bottom",
                    render: () => {
                        return (
                            <CustomToast
                                onPress={() =>
                                    navigation.navigate("edit", {
                                        id: created.id,
                                    })
                                }
                                text={`"${created.title}" has been created.`}
                                action={"View"}
                            />
                        );
                    },
                });
            }
        }
    }, [route.params]);

    function getDeals() {
        setIsLoading(true);
        api.business()
            .getDeals({ id: user.business && user.business.id })
            .then((res) => {
                var deals = helpers.convertUTCDateTimesToLocal(res.data.body.deals, ["starting", "ending"]);
                setDeals(deals);
                setLogo(helpers.rebaseImage(res.data.body?.logo?.thumb));
                setIsLoading(false);
            })
            .catch((err) => {
                setIsLoading(false);
            });
    }

    function activeDealFilter(deal) {
        var now = new Date();
        return (
            (helpers.formatDateTimeToDate(deal.starting) < now && helpers.formatDateTimeToDate(deal.ending) > now) ||
            deal.repeat !== "never"
        );
    }

    function upcomingDealFilter(deal) {
        var now = new Date();
        return helpers.formatDateTimeToDate(deal.starting) > now && deal.repeat == "never";
    }

    function pastDealFilter(deal) {
        var now = new Date();
        return helpers.formatDateTimeToDate(deal.ending) < now && deal.repeat == "never";
    }

    return (
        <>
            <StatusBar style={useColorModeValue("dark", "light")} animated={true} />
            <Container maxWidth={600}>
                <SafeArea />
                <VStack padding="4" space="8">
                    <HStack justifyContent={"space-between"} alignItems="center">
                        <Heading size="xl">My deals</Heading>
                        <Button
                            leftIcon={<MaterialIcons name="add" size={20} color="white" />}
                            onPress={() => navigation.navigate("create")}
                        >
                            Create a deal
                        </Button>
                    </HStack>
                </VStack>
            </Container>
            <TabNavigator
                style={{
                    width: "100%",
                    maxWidth: "100%",
                    marginHorizontal: "auto",
                }}
                tabBarStyle={{
                    marginHorizontal: "auto",
                    width: "100%",
                    maxWidth: 600,
                }}
                sceneContainerStyle={{
                    backgroundColor: "transparent",
                }}
                screens={[
                    {
                        name: "active",
                        title: "Active deals",
                        children: () => (
                            <ScrollView>
                                <Container maxWidth={600}>
                                    <Box p={4}>
                                        <DealsList
                                            linkToEdit
                                            logo={logo}
                                            deals={deals?.filter((deal) => activeDealFilter(deal))}
                                            isLoading={isLoading}
                                            emptyHeading={"No active deals"}
                                        />
                                    </Box>
                                </Container>
                            </ScrollView>
                        ),
                    },
                    {
                        name: "upcoming",
                        title: "Upcoming deals",
                        children: () => (
                            <ScrollView>
                                <Container maxWidth={600}>
                                    <Box p={4}>
                                        <DealsList
                                            linkToEdit
                                            logo={logo}
                                            deals={deals?.filter((deal) => upcomingDealFilter(deal))}
                                            isLoading={isLoading}
                                            emptyHeading={"No upcoming deals"}
                                        />
                                    </Box>
                                </Container>
                            </ScrollView>
                        ),
                    },
                    {
                        name: "past",
                        title: "Past deals",
                        children: () => (
                            <ScrollView>
                                <Container maxWidth={600}>
                                    <Box p={4}>
                                        <DealsList
                                            linkToEdit
                                            logo={logo}
                                            deals={deals?.filter((deal) => pastDealFilter(deal))}
                                            isLoading={isLoading}
                                            emptyHeading={"No past deals"}
                                        />
                                    </Box>
                                </Container>
                            </ScrollView>
                        ),
                    },
                ]}
            />
        </>
    );
}
