import axios from "axios";
import React, { useState } from "react";
import Autocomplete from "./Autocomplete";

export default function AddressAutocomplete({ defaultValue, onSelect, required, isDisabled }) {
    const [addressList, setAddressList] = useState([]);
    const [selectedAddress, setSelectedAddress] = useState(null);

    function addressSearch(query) {
        axios
            .get(
                `https://api.mapbox.com/geocoding/v5/mapbox.places/${query}.json?proximity=ip&access_token=pk.eyJ1IjoiYXBhcnZlejE3IiwiYSI6ImNrcXE4cDR5eTB6MnIyb3A1enFxZGY2MmoifQ.jUOYgWPwQXRhJHdRIn_s3g`
            )
            .then((res) => setAddressList(res.data.features))
            .catch((err) => {
                console.log(err.response.data);
            });
    }
    return (
        <Autocomplete
            required={required}
            options={addressList?.map((address) => {
                return { value: address.id, label: address.place_name };
            })}
            defaultValue={defaultValue}
            label="Address"
            onChangeText={(text) => addressSearch(text)}
            onSelect={(selected) => onSelect(addressList.find((item) => item.id === selected.value))}
            isDisabled={isDisabled}
        />
    );
}
