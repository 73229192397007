import { Box, Button, Divider, Heading, HStack, ScrollView, Skeleton, Spinner, Text, VStack } from "native-base";
import React, { useContext, useEffect, useState } from "react";
import { ImageBackground, Linking } from "react-native";
import Container from "../components/Container/Container";
import api from "../services/api";
import location from "../services/location";
import DealsList from "../components/List/DealsList";
import { UserLocationContext } from "../context/UserLocationContext";
import helpers from "../utils/helpers";
import { UserContext } from "../context/UserContext";
import ImageLoader from "../components/Loader/ImageLoader";
import PendingVerificationAlert from "../components/Alert/PendingVerificationAlert";
import LocationSplash from "../components/Location/LocationSplash";
import Logo from "../components/Logo/Logo";
import { useLinkTo } from "@react-navigation/native";

export default function WelcomeScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);
    const linkTo = useLinkTo();

    // User current location coordinates
    const [userCoords, setUserCoords] = useState({ lat: 0, lng: 0 });
    // User location context
    const { userLocation, setUserLocation } = useContext(UserLocationContext);

    const [deals, setDeals] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    const [mapIsLoading, setMapIsLoading] = useState(false);
    const [locationError, setLocationError] = useState(null);

    const [page, setPage] = useState(1);
    const [isPaginating, setIsPaginating] = useState(false);
    const [paginationComplete, setPaginationComplete] = useState(false);

    const scrollIsCloseToBottom = ({ layoutMeasurement, contentOffset, contentSize }) => {
        const paddingToBottom = 20;
        return layoutMeasurement.height + contentOffset.y >= contentSize.height - paddingToBottom;
    };

    useEffect(() => {
        getUserLocation();
    }, []);

    async function getUserLocation() {
        setMapIsLoading(true);
        setIsLoading(true);
        const coords = await location.requestUserLocation();
        if (!coords) {
            setLocationError("We could not access your location.");
        } else {
            setUserLocation(coords);
            setUserCoords(coords);
            setMapIsLoading(false);
            getDeals(coords, 1);
        }
    }

    function getDeals(latLng, page) {
        setIsLoading(true);
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        if (!paginationComplete) {
            setIsPaginating(true);
        }
        api.deal()
            .getAll({ lat: latLng?.lat, lng: latLng?.lng, page: page, timezone: timezone })
            .then((res) => {
                if (res.data.body.length == 0) {
                    setPaginationComplete(true);
                }
                setIsPaginating(false);
                setIsLoading(false);
                var newDeals = helpers.convertUTCDateTimesToLocal(res.data.body, ["starting", "ending"]);

                if (deals) {
                    let allDeals = Arrray.from(new Set([...deals, ...newDeals]));
                    setDeals(allDeals);
                } else {
                    setDeals(newDeals);
                }
                setPage(page + 1);
            })
            .catch((err) => {
                setIsLoading(false);
                setIsPaginating(false);
            });
    }

    return (
        <>
            <ScrollView
                showsVerticalScrollIndicator={false}
                onScroll={({ nativeEvent }) => {
                    if (scrollIsCloseToBottom(nativeEvent) && !paginationComplete) {
                        if (!isPaginating) {
                            getDeals(userLocation, page);
                        }
                    }
                }}
                scrollEventThrottle={400}
            >
                {locationError ? (
                    <LocationSplash />
                ) : (
                    <Container backgroundColor={"transparent"}>
                        {user?.business?.is_verified == false ? (
                            <PendingVerificationAlert business={user.business.business_name} />
                        ) : (
                            <></>
                        )}

                        <VStack padding={4} space={5}>
                            <Heading size="xl" maxW={300}>
                                Find great food {"\n"}deals near you!
                            </Heading>

                            <HStack alignItems={"center"}>
                                <Skeleton bg={"green.600"} width={2} height={2} borderRadius={10} mr={2}></Skeleton>
                                <Text>Live now</Text>
                            </HStack>

                            <DealsList
                                deals={deals}
                                isLoading={isLoading}
                                responsiveColumns
                                emptyHeading={"No deal!"}
                                emptyText={"Looks like there aren't any active deals nearby."}
                            />

                            <Box>
                                {!paginationComplete ? (
                                    <>{isPaginating ? <Spinner></Spinner> : <></>}</>
                                ) : (
                                    <>
                                        {(deals?.length !== 0 || !deals) && (
                                            <Box alignItems="center">
                                                <Text color="gray.500">No more deals</Text>
                                            </Box>
                                        )}
                                    </>
                                )}
                            </Box>
                        </VStack>
                    </Container>
                )}

                <Container>
                    <Box mb={8} px={4}>
                        <ImageBackground
                            style={{ borderRadius: 20, overflow: "hidden" }}
                            source={{ uri: require("./../assets/images/apps-background.jpg") }}
                        >
                            <VStack py={20} px={4} space={4} textAlign="center" alignItems="center">
                                <Heading color="white">Want the app?</Heading>
                                <Text color="white">Download Dealio on the App Store and Google Play.</Text>
                                <HStack space={4} mt={4}>
                                    <Button
                                        variant="unstyled"
                                        p={0}
                                        onPress={() =>
                                            Linking.openURL("https://apps.apple.com/us/app/dealio-it/id1639843062")
                                        }
                                    >
                                        <ImageLoader
                                            source={require("./../assets/images/app-store-badge.png")}
                                            style={{
                                                width: 120,
                                                height: 40,
                                            }}
                                        />
                                    </Button>
                                    <Button
                                        p={0}
                                        variant="unstyled"
                                        onPress={() =>
                                            Linking.openURL(
                                                "https://play.google.com/store/apps/details?id=com.dealioit.dealio"
                                            )
                                        }
                                    >
                                        <ImageLoader
                                            source={require("./../assets/images/google-play-badge.png")}
                                            style={{
                                                width: 137,
                                                height: 40,
                                            }}
                                        />
                                    </Button>
                                </HStack>
                            </VStack>
                        </ImageBackground>
                    </Box>

                    <Divider></Divider>

                    <ScrollView
                        horizontal
                        contentContainerStyle={{
                            alignItems: "center",
                            justifyContent: "space-between",
                            width: "100%",
                            height: "60px",
                        }}
                    >
                        <Box mr={5} pl={4}>
                            <Logo color={"#ccc"} />
                        </Box>

                        <HStack alignItems={"center"} pr={4}>
                            <Button variant="link" onPress={() => linkTo("/businesses")}>
                                Dealio for business
                            </Button>
                            <Button variant="link" onPress={() => linkTo("/contact")}>
                                Contact us
                            </Button>
                            <Button variant="link" onPress={() => linkTo("/terms")}>
                                Terms
                            </Button>
                            <Button variant="link" onPress={() => linkTo("/privacy")}>
                                Privacy
                            </Button>
                        </HStack>
                    </ScrollView>
                </Container>
            </ScrollView>
        </>
    );
}
