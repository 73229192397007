import React from "react";
import PropTypes from "prop-types";
import { View } from "react-native";
import { useColorModeValue } from "native-base";

export default function Container({
    style,
    fluid,
    width,
    maxWidth,
    top,
    bottom,
    centerContent,
    noGutter,
    children,
    backgroundColor,
    transparent,
}) {
    return (
        <View
            style={[
                style,
                {
                    width: "100%" || width,
                    maxWidth: fluid ? "100%" : maxWidth ? maxWidth : 1200,
                    alignItems: centerContent ? "center" : null,
                    alignSelf: "center",
                    backgroundColor: transparent ? "transparent" : useColorModeValue("#dark.50", "dark.900"),
                },
            ]}
        >
            {children}
        </View>
    );
}

Container.defaultProps = {
    style: null,
    fluid: false,
    maxWidth: null,
    top: null,
    bottom: null,
    centerContent: false,
    noGutter: false,
    backgroundColor: null,
    children: null,
    transparent: false,
};

Container.propTypes = {
    fluid: PropTypes.bool,
    maxWidth: PropTypes.number,
    top: PropTypes.string,
    bottom: PropTypes.string,
    centerContent: PropTypes.bool,
    noGutter: PropTypes.bool,
    backgroundColor: PropTypes.string,
    children: PropTypes.node,
    transparent: PropTypes.bool,
};
