import { View, Text } from "react-native";
import React from "react";
import Svg, { Path } from "react-native-svg";

export default function Logo({ color }) {
    return (
        <Svg xmlns="http://www.w3.org/2000/svg" width="65" height="20" fill="none" viewBox="0 0 65 20">
            <Path
                fill={color || "#FF0D0D"}
                d="M9.2 19.325c-1 0-2.017-.442-3.05-1.325l-.2 1h-3.6l3.4-15.9 3.7-.5-3 14.025c.467.333.942.5 1.425.5.95 0 1.783-.55 2.5-1.65.733-1.1 1.3-2.5 1.7-4.2.4-1.7.6-3.417.6-5.15 0-1.633-.325-2.825-.975-3.575-.633-.767-1.525-1.15-2.675-1.15-1.25 0-2.375.25-3.375.75a5.558 5.558 0 00-2.325 2.1c-.567.917-.85 1.992-.85 3.225 0 .617.058 1.05.175 1.3.117.233.175.367.175.4-.867 0-1.533-.183-2-.55C.375 8.258.15 7.667.15 6.85c0-1.133.425-2.208 1.275-3.225.85-1.017 1.983-1.833 3.4-2.45C6.258.558 7.8.25 9.45.25c2.283 0 3.967.675 5.05 2.025 1.083 1.35 1.625 3.075 1.625 5.175 0 1.9-.325 3.775-.975 5.625-.633 1.833-1.483 3.333-2.55 4.5-1.067 1.167-2.2 1.75-3.4 1.75zM20.152 19.15c-1.233 0-2.192-.317-2.875-.95-.683-.65-1.025-1.658-1.025-3.025 0-1.15.225-2.417.675-3.8a8.722 8.722 0 012.2-3.575c1.017-1.017 2.309-1.525 3.875-1.525 1.834 0 2.75.8 2.75 2.4a4.48 4.48 0 01-.8 2.575 6.011 6.011 0 01-2.125 1.9 6.975 6.975 0 01-2.825.8c-.033.5-.05.833-.05 1 0 .817.142 1.375.425 1.675.284.283.742.425 1.375.425.9 0 1.667-.208 2.3-.625.65-.417 1.358-1.05 2.125-1.9h.85c-1.85 3.083-4.142 4.625-6.875 4.625zm.05-6.4c.617-.033 1.2-.25 1.75-.65a4.402 4.402 0 001.35-1.525c.35-.617.525-1.267.525-1.95S23.62 7.6 23.202 7.6c-.6 0-1.192.525-1.775 1.575-.567 1.05-.975 2.242-1.225 3.575z"
            ></Path>
            <Path
                fill={color || "#FF0D0D"}
                d="M29.172 19.15c-.95 0-1.742-.325-2.375-.975-.634-.65-.95-1.667-.95-3.05 0-1.233.241-2.525.725-3.875.5-1.367 1.233-2.517 2.2-3.45.983-.95 2.15-1.425 3.5-1.425.683 0 1.192.117 1.525.35.333.233.5.542.5.925v.175l.275-1.325h3.6l-1.8 8.5c-.067.25-.1.517-.1.8 0 .717.342 1.075 1.025 1.075.467 0 .867-.217 1.2-.65.35-.433.625-1 .825-1.7h1.05c-.617 1.8-1.383 3.025-2.3 3.675-.9.633-1.809.95-2.725.95-.7 0-1.267-.192-1.7-.575-.417-.4-.667-.975-.75-1.725a7.084 7.084 0 01-1.625 1.675c-.583.417-1.284.625-2.1.625zm1.625-2.425c.416 0 .825-.192 1.225-.575.416-.4.7-.942.85-1.625l1.2-5.65c0-.217-.084-.425-.25-.625-.167-.217-.425-.325-.775-.325-.667 0-1.267.392-1.8 1.175-.534.767-.95 1.7-1.25 2.8-.3 1.083-.45 2.042-.45 2.875 0 .833.116 1.367.35 1.6.25.233.55.35.9.35z"
            ></Path>
            <Path
                fill={color || "#FF0D0D"}
                d="M41.806 19.15c-.8 0-1.45-.25-1.95-.75-.483-.5-.725-1.25-.725-2.25 0-.417.067-.958.2-1.625L42.006 2l3.7-.5L42.831 15c-.067.25-.1.517-.1.8 0 .333.075.575.225.725.167.133.433.2.8.2.483 0 .933-.2 1.35-.6.417-.417.717-.95.9-1.6h1.05c-.617 1.8-1.408 3.025-2.375 3.675-.967.633-1.925.95-2.875.95z"
            ></Path>
            <Path
                fill={color || "#FF0D0D"}
                d="M50.171 5.15c-.55 0-1.017-.192-1.4-.575a1.905 1.905 0 01-.575-1.4c0-.55.192-1.017.575-1.4.383-.4.85-.6 1.4-.6.55 0 1.017.2 1.4.6.4.383.6.85.6 1.4 0 .55-.2 1.017-.6 1.4-.383.383-.85.575-1.4.575zm-1.7 14c-.8 0-1.45-.25-1.95-.75-.483-.5-.725-1.25-.725-2.25 0-.417.067-.958.2-1.625l1.7-8.025h3.6l-1.8 8.5c-.067.25-.1.517-.1.8 0 .333.075.575.225.725.167.133.433.2.8.2.483 0 .933-.2 1.35-.6.417-.417.717-.95.9-1.6h1.05c-.617 1.8-1.408 3.025-2.375 3.675-.967.633-1.925.95-2.875.95z"
            ></Path>
            <Path
                fill={color || "#FF0D0D"}
                d="M56.311 19.1c-1.233 0-2.208-.317-2.925-.95-.717-.633-1.075-1.633-1.075-3 0-1.15.225-2.408.675-3.775a8.722 8.722 0 012.2-3.575c1.017-1.017 2.317-1.525 3.9-1.525 1.967 0 3.025 1.125 3.175 3.375.367-.083.692-.283.975-.6a8.42 8.42 0 00.8-1.125h.8c-.45.9-.85 1.608-1.2 2.125-.35.5-.808.908-1.375 1.225-.1 1.567-.425 2.942-.975 4.125-.533 1.183-1.233 2.1-2.1 2.75a4.762 4.762 0 01-2.875.95zm1-2.25c.617 0 1.192-.5 1.725-1.5.533-1 .9-2.233 1.1-3.7-.4-.15-.6-.525-.6-1.125 0-.65.233-1.083.7-1.3-.033-.533-.125-.9-.275-1.1-.133-.217-.358-.325-.675-.325-.55 0-1.075.4-1.575 1.2-.5.783-.908 1.742-1.225 2.875-.3 1.117-.45 2.1-.45 2.95 0 .8.092 1.342.275 1.625.184.267.517.4 1 .4z"
            ></Path>
        </Svg>
    );
}
