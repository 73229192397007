import { extendTheme, NativeBaseProvider, theme, themeTools } from "native-base";

const Theme = extendTheme({
    config: {
        useSystemColorMode: true,
    },
    colors: {
        primary: {
            50: "#ffe6e6",
            100: "#f8bdbd",
            200: "#ed9192",
            300: "#e56767",
            400: "#dc3c3c",
            500: "#c32423",
            600: "#981a1a",
            700: "#6e1212",
            800: "#430809",
            900: "#1d0000",
        },
        secondary: {
            50: "#f2f2f2",
            100: "#d9d9d9",
            200: "#bfbfbf",
            300: "#a6a6a6",
            400: "#8c8c8c",
            500: "#737373",
            600: "#595959",
            700: "#404040",
            800: "#262626",
            900: "#0d0d0d",
        },
        dark: {
            50: "#f9f9f9",
            100: "#e7e7e8",
            200: "#cfcfd1",
            300: "#9ea0a2",
            400: "#86888b",
            500: "#6e7074",
            600: "#56585d",
            700: "#3d4145",
            800: "#25292e",
            900: "#0d1117",
        },
    },
    fontConfig: {
        Poppins: {
            300: {
                normal: "Poppins_300Light",
                italic: "Poppins_300Light",
            },
            400: {
                normal: "Poppins_400Regular",
                italic: "Poppins_400Regular_Italic",
            },
            600: {
                normal: "Poppins_600SemiBold",
                italic: "Poppins_600SemiBold",
            },
            700: {
                normal: "Poppins_700Bold",
                italic: "Poppins_700Bold_Italic",
            },
        },
    },
    fonts: {
        heading: "Poppins_600SemiBold",
        body: "Poppins_600SemiBold",
        mono: "Poppins_400Regular",
    },
    shadows: {
        5: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 4,
            },
            shadowOpacity: 0.1,
            shadowRadius: 5,
            elevation: 6,
        },
        10: {
            shadowColor: "#000",
            shadowOffset: {
                width: 0,
                height: 10,
            },
            shadowOpacity: 0.1,
            shadowRadius: 20,
            elevation: 10,
        },
    },
    components: {
        Heading: {
            baseStyle: {
                letterSpacing: "md",
                lineHeight: "sm",
                fontSize: "sm",
                color: "primary.600",
            },
        },
        Button: {
            baseStyle: {
                borderRadius: "xl",
                _text: {
                    paddingLeft: 2,
                    paddingRight: 2,
                },
            },
            sizes: {
                md: {
                    py: 3,
                    _text: {
                        paddingLeft: 2,
                        paddingRight: 2,
                    },
                },
                sm: {
                    py: 1,
                    _text: {
                        paddingLeft: 1,
                        paddingRight: 1,
                    },
                },
            },
        },
        IconButton: {
            baseStyle: {
                borderRadius: "3xl",
            },
            defaultProps: {
                variant: "ghost",
                size: "lg",
            },
            variants: {
                ghost: {
                    baseStyle: {
                        backgroundColor: "#f9f9f9",
                    },
                    _hover: {
                        backgroundColor: "secondary.100",
                        _dark: {
                            backgroundColor: "dark.800",
                        },
                    },
                    _pressed: {
                        backgroundColor: "secondary.200",
                        _dark: {
                            backgroundColor: "dark.700",
                        },
                    },
                },
            },
        },
        Input: {
            baseStyle: {
                fontFamily: "body",
                borderRadius: theme.radii["xl"],
                padding: 3,

                _light: {
                    backgroundColor: "white",
                    borderColor: "dark.200",
                    _focus: {
                        backgroundColor: "dark.50",
                    },
                    _disabled: {
                        backgroundColor: "gray.200",
                        _web: {
                            disabled: true,
                            cursor: "not-allowed",
                        },
                    },
                },
                _dark: {
                    backgroundColor: "dark.800",
                    borderColor: "dark.800",
                    _focus: {
                        backgroundColor: "dark.700",
                    },
                    _disabled: {
                        backgroundColor: "gray.400",
                        _web: {
                            disabled: true,
                            cursor: "not-allowed",
                        },
                    },
                },
            },

            sizes: {
                sm: { fontSize: "sm" },
                md: { fontSize: "lg" },
                xl: { fontSize: "lg" },
                "2xl": { fontSize: "lg" },
            },
        },
        FormControl: {
            baseStyle: {
                shadowColor: "#000",
                shadowOffset: {
                    width: 0,
                    height: 4,
                },
                shadowOpacity: 0.08,
                shadowRadius: 5,
                elevation: 6,
            },
        },
        FormControlLabel: {
            baseStyle: {
                _text: {
                    fontFamily: "heading",
                },
            },
        },
    },
});

export default Theme;
