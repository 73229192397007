import * as React from "react";
import { createMaterialTopTabNavigator } from "@react-navigation/material-top-tabs";
import { useColorModeValue, useToken } from "native-base";

const Tab = createMaterialTopTabNavigator();

export default function TabNavigator({ screens, width, style, sceneContainerStyle, tabBarStyle }) {
    return (
        <>
            <Tab.Navigator
                style={style}
                sceneContainerStyle={sceneContainerStyle}
                screenOptions={{
                    tabBarLabelStyle: {
                        fontSize: 16,
                        fontFamily: "Poppins_600SemiBold",
                        textTransform: "none",
                    },
                    tabBarItemStyle: { paddingLeft: 0, paddingRight: 0 },
                    tabBarIndicatorStyle: {
                        backgroundColor: useToken("colors", "primary.500"),
                    },
                    tabBarPressColor: "transparent",
                    tabBarStyle: {
                        backgroundColor: "transparent",
                        ...tabBarStyle,
                    },
                    tabBarActiveTintColor: useToken("colors", "primary.500"),
                    tabBarInactiveTintColor: useToken("colors", useColorModeValue("black", "dark.400")),
                    tabBarContentContainerStyle: {
                        width: width,
                    },
                }}
            >
                {screens.map((screen, i) => (
                    <Tab.Screen
                        key={i}
                        name={screen.name}
                        children={screen.children}
                        options={{
                            tabBarLabel: screen.name,
                            title: screen.title,
                        }}
                    />
                ))}
            </Tab.Navigator>
        </>
    );
}
