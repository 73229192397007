import { Box, Button, Center, Heading, Input, Text, VStack } from "native-base";
import { useContext, useEffect, useState } from "react";
import api from "../services/api";
import { UserContext } from "../context/UserContext";
import { Platform } from "react-native";
import storage from "../services/storage";
import Container from "../components/Container/Container";
import { AuthContext } from "../context/AuthContext";
import { StatusBar } from "expo-status-bar";
import ActionBar from "../components/Topbar/ActionBar";
import authentication from "../services/authentication";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import axiosIntercept from "../services/axiosIntercept";
import { useLinkTo } from "@react-navigation/native";
import * as Analytics from "expo-firebase-analytics";
import SafeArea from "../components/SafeArea/SafeArea";

export default function LoginScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);
    const { auth, setAuth } = useContext(AuthContext);

    const linkTo = useLinkTo();

    const [email, setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [deviceName, setDeviceName] = useState(null);

    const [loggingIn, setLoggingIn] = useState(false);

    const [error, setError] = useState(null);

    useEffect(() => {
        const name = authentication.getDeviceName();
        setDeviceName(name);
    }, []);

    function attemptLogin() {
        setLoggingIn(true);
        api.auth()
            .login({
                email: email,
                password: password,
                device_name: deviceName,
            })
            .then((res) => {
                setUser(res.data.body.user);
                axiosIntercept.setAuthHeader(res.data.body.token);
                storage.setItem("token", res.data.body.token).then(() => {
                    setAuth(true);
                    navigation.pop();
                    Platform.OS == "web" && linkTo("/welcome");
                });
                Analytics.setUserId(res.data.body?.user?.id);
                Analytics.logEvent("logged_in", {
                    sender: "action",
                    user: res.data.body.user?.id,
                    screen: `Log in`,
                    purpose: "Logged in",
                });
            })
            .catch((err) => {
                setLoggingIn(false);
                err.response && setError(err.response.data.message);
            });
    }

    return (
        <>
            <KeyboardAwareScrollView extraScrollHeight={100} enableOnAndroid>
                <SafeArea transparent />
                <ActionBar padding={4} close closeOnPress={() => navigation.goBack()} />

                <Box height="100%" justifyContent={"center"}>
                    {Platform.OS == "ios" && <StatusBar style="light" animated={true} />}
                    <Container maxWidth={400}>
                        <VStack space={3} padding={8}>
                            <Heading size="xl">Log in</Heading>
                            {error && <Text color={"error.500"}>{error}</Text>}
                            <Input
                                placeholder="Email"
                                onChangeText={(text) => {
                                    setEmail(text);
                                    setError(null);
                                }}
                            ></Input>
                            <Input
                                placeholder="Password"
                                type="password"
                                onChangeText={(text) => {
                                    setPassword(text);
                                    setError(null);
                                }}
                            ></Input>
                            <Button variant="link" onPress={() => navigation.navigate("forgotpassword")}>
                                Forgot password?
                            </Button>
                            <Button onPress={() => attemptLogin()} isLoading={loggingIn} isLoadingText="Logging in...">
                                Login
                            </Button>
                        </VStack>

                        <Center marginBottom={20}>
                            <Text>Don't have an account?</Text>
                            <Button variant="link" onPress={() => navigation.navigate("register")}>
                                Create an account
                            </Button>
                        </Center>
                    </Container>
                </Box>
            </KeyboardAwareScrollView>
        </>
    );
}
