import { Platform, PlatformColor, StatusBar, View } from "react-native";
import React from "react";
import Container from "../Container/Container";
import { Box, Button, Center, Heading, Icon, Text, VStack } from "native-base";
import { Link, useLinkTo, useNavigation } from "@react-navigation/native";
import SafeArea from "../SafeArea/SafeArea";
import Logo from "../Logo/Logo";
import ActionBar from "../Topbar/ActionBar";
import { Ionicons } from "@expo/vector-icons";

export default function AuthSplash({ heading, text }) {
    const navigation = useNavigation();
    const linkTo = useLinkTo();
    return (
        <>
            <StatusBar style="dark" animated={true} />

            {Platform.OS !== "web" && (
                <ActionBar borderBottom>
                    <Box width="100%" alignItems="center">
                        <Logo></Logo>
                    </Box>
                </ActionBar>
            )}

            <Container>
                <Center height={"90%"} minH={500}>
                    <VStack alignItems={"center"} space={6}>
                        <Box maxWidth={300}>
                            <Heading size="xl" textAlign={"center"}>
                                {heading}
                            </Heading>
                            <Text textAlign={"center"}>{text}</Text>
                        </Box>

                        <Button onPress={() => linkTo("/register")}>Join Dealio</Button>

                        <Text>
                            Already have an account?
                            <Heading size={"xs"}>
                                <Link to={"/login"}> Log in</Link>
                            </Heading>
                        </Text>
                    </VStack>
                </Center>
            </Container>
        </>
    );
}
