import { Box } from "native-base";
import { Platform } from "react-native";
import Map from "../components/Map/Map";
import SafeArea from "../components/SafeArea/SafeArea";

export default function MapScreen({ route, navigation }) {
    const { lat, lng, businesses } = route.params;
    return (
        <Box height={"100%"}>
            {Platform.OS == "android" && <SafeArea />}
            <Map showBackButton isLoading={false} height="100%" lat={lat} lng={lng} businesses={businesses} />
        </Box>
    );
}
