import {
    Box,
    Button,
    Column,
    FormControl,
    Heading,
    Input,
    Row,
    Text,
    TextArea,
    useColorModeValue,
    VStack,
} from "native-base";
import { useContext, useState } from "react";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Container from "../components/Container/Container";
import CurrencyInput from "../components/Input/CurrencyInput";
import DateTimeInput from "../components/Input/DateTimeInput";
import SelectInput from "../components/Input/SelectInput";
import ActionBar from "../components/Topbar/ActionBar";
import api from "../services/api";
import ImageUploader from "../components/Upload/ImageUploader";
import { UserContext } from "../context/UserContext";
import { Platform } from "react-native";
import { StatusBar } from "expo-status-bar";
import SafeArea from "../components/SafeArea/SafeArea";
import * as Analytics from "expo-firebase-analytics";

export default function CreateDealScreen({ navigation }) {
    const { user, setUser } = useContext(UserContext);

    const [formData, setData] = useState({
        repeat: "never",
    });

    const [image, setImage] = useState(null);
    const [errors, setErrors] = useState({});
    const [creating, setCreating] = useState(false);

    const validate = () => {
        if (image == null) {
            setErrors({ ...errors, image: "An image is required" });
            return false;
        } else if (formData.title == null || formData.title === "") {
            setErrors({ ...errors, title: "Title is required" });
            return false;
        } else if (formData.starting == null) {
            setErrors({
                ...errors,
                starting: "Starting date and time is required",
            });
            return false;
        } else if (formData.ending == null) {
            setErrors({
                ...errors,
                ending: "Ending date and time is required",
            });
            return false;
        } else {
            return true;
        }
    };

    const onSubmit = () => {
        for (const key in errors) {
            delete errors[key];
        }
        if (validate()) {
            createDeal();
        }
    };

    function createDeal() {
        setCreating(true);

        let fd = new FormData();

        if (Platform.OS == "web") {
            const file = new File([image.blob], image.name, {
                type: `image/${image.type}`,
            });
            fd.append("image", file);
        } else {
            fd.append("image", { type: image.type, uri: image.uri, name: image.name });
        }

        fd.append("title", formData.title);
        formData.description && fd.append("description", formData.description);
        formData.original_price && fd.append("original_price", formData.original_price);
        formData.deal_price && fd.append("deal_price", formData.deal_price);
        fd.append("starting", formData.starting);
        fd.append("ending", formData.ending);
        fd.append("repeat", formData.repeat);

        return api
            .deal()
            .create({ business_id: user?.business?.id }, fd)
            .then((res) => {
                setCreating(false);
                onCreateSuccess(res.data.body);
            })
            .catch((err) => {
                setCreating(false);
                setErrors(err.response?.data?.errors);
            });
    }

    function onCreateSuccess(deal) {
        Analytics.logEvent("created_deal", {
            sender: "action",
            user: user?.id,
            screen: `Create deal`,
            dealId: deal.id,
            purpose: "Created a new deal",
        });
        navigation.navigate("home", {
            refetch: true,
            createdDeal: deal,
        });
    }

    return (
        <>
            <StatusBar animated style={useColorModeValue("light", "dark")} />
            {Platform.OS == "android" && <SafeArea />}
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <Container maxWidth={600}>
                    <VStack space={5} padding={4}>
                        <ActionBar back backOnPress={() => navigation.goBack()} />
                        <Heading size="xl">Create a deal</Heading>

                        {errors && "image" in errors && (
                            <Text marginTop={3} color={"error.500"}>
                                {errors?.image}
                            </Text>
                        )}

                        <ImageUploader image={image} onChange={(result) => setImage(result)} />

                        <FormControl isRequired>
                            <FormControl.Label>Title</FormControl.Label>
                            <Input
                                type="text"
                                placeholder="e.g. Burger, Chips & Drink"
                                onChangeText={(value) => setData({ ...formData, title: value })}
                            />

                            {errors && "title" in errors && (
                                <Text marginTop={3} color={"error.500"}>
                                    {errors?.title}
                                </Text>
                            )}
                        </FormControl>

                        <FormControl>
                            <FormControl.Label>Description</FormControl.Label>
                            <TextArea
                                type="textarea"
                                placeholder="Some more info about your deal"
                                onChangeText={(value) => setData({ ...formData, description: value })}
                            />
                        </FormControl>

                        <Row space={5}>
                            <Column width={"50%"}>
                                <CurrencyInput
                                    label="Original price"
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            original_price: parseInt(parseFloat(value) * 100),
                                        })
                                    }
                                />
                            </Column>

                            <Column width={"50%"}>
                                <CurrencyInput
                                    label="Deal price"
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            deal_price: parseInt(parseFloat(value) * 100),
                                        })
                                    }
                                />
                            </Column>
                        </Row>

                        <DateTimeInput
                            defaultValue={new Date()}
                            onChangeText={(value) =>
                                setData({
                                    ...formData,
                                    starting: value,
                                })
                            }
                            isRequired
                            label="Starting"
                        />
                        {errors && "starting" in errors && (
                            <Text marginTop={3} color={"error.500"}>
                                {errors.starting}
                            </Text>
                        )}

                        <DateTimeInput
                            defaultValue={new Date(new Date().setHours(new Date().getHours() + 2))}
                            onChangeText={(value) =>
                                setData({
                                    ...formData,
                                    ending: value,
                                })
                            }
                            isRequired
                            label="Ending"
                        />
                        {errors && "ending" in errors && (
                            <Text marginTop={3} color={"error.500"}>
                                {errors.ending}
                            </Text>
                        )}

                        <SelectInput
                            defaultValue={"never"}
                            options={[
                                { value: "never", label: "never" },
                                { value: "daily", label: "daily" },
                                { value: "weekly", label: "weekly" },
                                { value: "weekdays", label: "weekdays" },
                            ]}
                            onValueChange={(value) =>
                                setData({
                                    ...formData,
                                    repeat: value,
                                })
                            }
                            isRequired
                            label="Repeat"
                        />
                        {errors && "repeat" in errors && (
                            <Text marginTop={3} color={"error.500"}>
                                {errors.repeat}
                            </Text>
                        )}
                    </VStack>
                </Container>
            </KeyboardAwareScrollView>

            <Container maxWidth={600}>
                <Box p={4}>
                    <Button
                        isLoading={creating}
                        isLoadingText="Creating"
                        onPress={() => {
                            onSubmit();
                        }}
                    >
                        Create deal
                    </Button>
                </Box>
            </Container>
        </>
    );
}
