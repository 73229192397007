import { Box, Button, Heading, Input, Text, Toast, VStack } from "native-base";
import { useContext, useState } from "react";
import api from "../services/api";
import { UserContext } from "../context/UserContext";
import { Platform } from "react-native";
import Container from "../components/Container/Container";
import { AuthContext } from "../context/AuthContext";
import { StatusBar } from "expo-status-bar";
import ActionBar from "../components/Topbar/ActionBar";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import CustomToast from "../components/Toast/CustomToast";

export default function ResetPasswordScreen({ navigation, route }) {
    const [formData, setData] = useState({
        token: "",
        email: null,
        password: "",
        password_confirmation: "",
    });

    const [errors, setErrors] = useState(null);

    function resetPassword() {
        api.auth()
            .resetPassword({
                email: route.params.email,
                token: route.params.token,
                password: formData.password,
                password_confirmation: formData.password_confirmation,
            })
            .then((res) => {
                onResetSuccess();
            })
            .catch((err) => {
                setErrors(err.response.data.errors);
            });
    }

    function onResetSuccess() {
        Toast.show({
            placement: "bottom",
            render: () => {
                return <CustomToast text={`Your password has been reset.`} />;
            },
        });
        navigation.navigate("auth", { screen: "login" });
    }

    return (
        <>
            <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
                <ActionBar padding={4} close closeOnPress={() => navigation.goBack()} />

                <Box height="100%" justifyContent={"center"}>
                    {Platform.OS == "ios" && <StatusBar style="light" animated={true} />}
                    <Container maxWidth={400}>
                        <VStack space={3} padding={8}>
                            <Heading size="xl">Reset password</Heading>
                            <Text>Enter a new password for your account.</Text>
                            {errors && <Text color={"error.500"}>{errors[0]}</Text>}

                            <Input placeholder="Email" value={route.params.email} isDisabled></Input>

                            {errors && "password" in errors && <Text color={"error.500"}>{errors.password}</Text>}

                            <Input
                                key={2}
                                onEndEditing={(event) => {
                                    if (event.nativeEvent.text.length === 0) {
                                        setData({
                                            ...formData,
                                            password: "",
                                        });
                                    }
                                }}
                                placeholder="New password"
                                type="password"
                                value={formData.password || ""}
                                onChangeText={(value) =>
                                    setData({
                                        ...formData,
                                        password: value,
                                    })
                                }
                            ></Input>

                            <Input
                                key={3}
                                placeholder="Repeat new password"
                                onEndEditing={(event) => {
                                    if (event.nativeEvent.text.length === 0) {
                                        setData({
                                            ...formData,
                                            password_confirmation: "",
                                        });
                                    }
                                }}
                                type="password"
                                value={formData.password_confirmation || ""}
                                onChangeText={(value) =>
                                    setData({
                                        ...formData,
                                        password_confirmation: value,
                                    })
                                }
                            ></Input>

                            <Button onPress={() => resetPassword()}>Reset Password</Button>
                        </VStack>
                    </Container>
                </Box>
            </KeyboardAwareScrollView>
        </>
    );
}
