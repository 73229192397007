/**
 * If you are not familiar with React Navigation, refer to the "Fundamentals" guide:
 * https://reactnavigation.org/docs/getting-started
 *
 */
import * as React from "react";
import { useContext, useEffect, useState } from "react";
import { NavigationContainer, useNavigationContainerRef } from "@react-navigation/native";
import { createNativeStackNavigator } from "@react-navigation/native-stack";

import { AuthContext } from "../context/AuthContext";
import { UserContext } from "../context/UserContext";

import LinkingConfiguration from "./LinkingConfiguration";
import BottomTabNavigator from "../navigation/BottomTabNavigator";

import Topbar from "../components/Topbar/Topbar";

import LoginScreen from "../screens/LoginScreen";
import RegisterScreen from "../screens/RegisterScreen";
import ContactScreen from "../screens/ContactScreen";
import TermsScreen from "../screens/TermsScreen";
import PrivacyScreen from "../screens/PrivacyScreen";
import ForBusinessScreen from "../screens/ForBusinessScreen";
import ForgotPasswordScreen from "../screens/ForgotPasswordScreen";
import ResetPasswordScreen from "../screens/ResetPasswordScreen";
import NotFoundScreen from "../screens/NotFoundScreen";

import authentication from "../services/authentication";
import api from "../services/api";
import axios from "axios";
import axiosIntercept from "../services/axiosIntercept";
import { setGoogleApiKey } from "expo-location";
import storage from "../services/storage";

import { useColorModeValue, useToken } from "native-base";
import { Platform } from "react-native";

import * as Analytics from "expo-firebase-analytics";

export default function Navigation() {
    const { auth, setAuth } = useContext(AuthContext);
    const { user, setUser } = useContext(UserContext);

    const navigationRef = useNavigationContainerRef();
    const routeNameRef = React.useRef();

    const [authChecked, setAuthChecked] = useState(false);

    useEffect(() => {
        setGoogleApiKey("AIzaSyC7CBMigZrrlkIcKxKr43eJxwLMakYcyTE");
        checkLoggedIn();
        axios.interceptors.response.use(
            function (response) {
                return response;
            },
            function (error) {
                if (error.response.status == 401) {
                    setUser(null);
                    setAuth(false);
                }
                return Promise.reject(error);
            }
        );
    }, []);

    async function checkLoggedIn() {
        await authentication.userIsLoggedIn().then((loggedIn) => {
            setAuth(loggedIn);
            if (loggedIn) {
                storage
                    .getItem("token")
                    .then((token) => {
                        axiosIntercept.setAuthHeader(token);
                    })
                    .then(() => {
                        getUser();
                    });
            } else {
                setUser(null);
                setAuth(false);
                storage.removeItem("token");
                delete axios.defaults.headers.common["Authorization"];
                setAuthChecked(true);
            }
        });
    }

    function getUser() {
        api.auth()
            .user()
            .then((res) => {
                setAuth(true);
                setUser(res.data.body);
                Analytics.setUserId(res.data.body?.id);
            })
            .catch((err) => {
                if (err.response.status == 401) {
                    setUser(null);
                    setAuth(false);
                    storage.removeItem("token");
                    delete axios.defaults.headers.common["Authorization"];
                }
            })
            .then(() => {
                setAuthChecked(true);
            });
    }

    return (
        <NavigationContainer
            ref={navigationRef}
            onStateChange={async () => {
                const previousRouteName = routeNameRef.current;
                const currentRouteName = navigationRef.getCurrentRoute().name;
                if (previousRouteName !== currentRouteName) {
                    await Analytics.logEvent("screen_view", { currentRouteName });
                }
                // Save the current route name for later comparison
                routeNameRef.current = currentRouteName;
            }}
            linking={LinkingConfiguration}
        >
            {Platform.OS == "web" && <Topbar />}
            {authChecked && <RootNavigator />}
        </NavigationContainer>
    );
}

/**
 * A root stack navigator is often used for displaying modals on top of all other content.
 * https://reactnavigation.org/docs/modal
 */
const Root = createNativeStackNavigator();

function RootNavigator() {
    const { auth, setAuth } = useContext(AuthContext);
    const { user, setUser } = useContext(UserContext);

    return (
        <>
            <Root.Navigator
                initialRouteName="welcome"
                screenOptions={{
                    headerShown: false,
                    contentStyle: {
                        backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                    },
                }}
            >
                <Root.Screen name="app" component={BottomTabNavigator} />
                <Root.Screen
                    name="contact"
                    component={ContactScreen}
                    options={{
                        title: "Dealio - Contact us",
                    }}
                />
                <Root.Screen
                    name="terms"
                    component={TermsScreen}
                    options={{
                        title: "Dealio - Terms & Conditions",
                    }}
                />
                <Root.Screen
                    name="privacy"
                    component={PrivacyScreen}
                    options={{
                        title: "Dealio - Privacy Policy",
                    }}
                />

                <Root.Screen
                    name="businesses"
                    component={ForBusinessScreen}
                    options={{
                        title: "Dealio for Business",
                    }}
                />

                <Root.Screen
                    name="auth"
                    component={AuthStack}
                    options={{
                        title: "Join Dealio",
                        presentation: "modal",
                    }}
                />

                <Root.Screen name="NotFound" component={NotFoundScreen} options={{ title: "Dealio - Oops!" }} />
            </Root.Navigator>
        </>
    );
}

const Auth = createNativeStackNavigator();

function AuthStack() {
    const { auth, setAuth } = useContext(AuthContext);
    return (
        <Auth.Navigator
            initialRouteName="login"
            screenOptions={{
                headerShown: false,
                contentStyle: {
                    backgroundColor: useToken("colors", useColorModeValue("dark.50", "dark.900")),
                },
            }}
        >
            <Auth.Screen
                name="login"
                component={LoginScreen}
                options={{
                    title: "Dealio - Login",
                }}
            />
            <Auth.Screen
                name="forgotpassword"
                component={ForgotPasswordScreen}
                options={{
                    title: "Dealio - Forgot password",
                }}
            />
            <Auth.Screen
                name="resetpassword"
                component={ResetPasswordScreen}
                options={{
                    title: "Dealio - Reset password",
                }}
            />
            <Auth.Screen
                name="register"
                component={RegisterScreen}
                options={{
                    title: "Join Dealio",
                }}
            />
        </Auth.Navigator>
    );
}
