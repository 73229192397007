import { Linking } from "react-native";
import React from "react";
import { Box, Button, Heading, Text, VStack } from "native-base";
import Container from "../components/Container/Container";
import ActionBar from "../components/Topbar/ActionBar";
import SafeArea from "../components/SafeArea/SafeArea";

export default function ContactScreen({ navigation }) {
    return (
        <Container maxWidth={600}>
            <SafeArea />
            <VStack space={4} p={4}>
                <ActionBar back backOnPress={() => navigation.goBack()} />
                <Heading>Get in touch</Heading>
                <Text>
                    Looking to get in touch with us? Send us an email to the following email address and we'll get back
                    to you soon.
                </Text>
                <Box alignItems="flex-start">
                    <Button onPress={() => Linking.openURL("mailto:contact@dealioit.com")}>contact@dealioit.com</Button>
                </Box>
            </VStack>
        </Container>
    );
}
