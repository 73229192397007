import React, { useContext, useState } from "react";
import { Platform, View } from "react-native";
import { UserContext } from "../context/UserContext";
import {
    Box,
    Button,
    Center,
    FormControl,
    Heading,
    HStack,
    Input,
    Text,
    useColorModeValue,
    useToken,
    VStack,
} from "native-base";
import MapView, { Marker } from "react-native-maps";
import { MaterialIcons } from "@expo/vector-icons";
import api from "../services/api";
import { StatusBar } from "expo-status-bar";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import AddressAutocomplete from "../components/Autocomplete/AddressAutocomplete";
import LogoUploader from "../components/Upload/LogoUploader";
import Container from "../components/Container/Container";
import { useLinkTo } from "@react-navigation/native";
import ActionBar from "../components/Topbar/ActionBar";
import SafeArea from "../components/SafeArea/SafeArea";
import * as Analytics from "expo-firebase-analytics";

export default function OnboardScreen({ navigation }) {
    const linkTo = useLinkTo();

    const { user, setUser } = useContext(UserContext);

    const [business, setBusiness] = useState(null);
    const [uploadingLogo, setUploadingLogo] = useState(false);

    const markerColor = useToken("colors", useColorModeValue("primary.600", "primary.300"));

    const [logo, setLogo] = useState(null);

    const [formData, setData] = useState({
        business_name: "",
        shop_name: "",
        address: "",
        address_match: "",
        lat: "",
        lng: "",
    });

    const [errors, setErrors] = useState({});

    const validate = () => {
        if (formData.logo === null) {
            setErrors({ logo: "A logo is required" });
            return false;
        } else if (formData.business_name === "") {
            setErrors({ business_name: "Business name is required" });
            return false;
        } else {
            return true;
        }
    };

    const onSubmit = () => {
        if (validate()) {
            createBusiness();
        }
    };

    function createBusiness() {
        let fd = new FormData();
        fd.append("business_name", formData.business_name);
        fd.append("shop_name", formData.shop_name);
        fd.append("address", formData.address);
        fd.append("lat", formData.lat);
        fd.append("lng", formData.lng);
        return api
            .business()
            .create(fd)
            .then((res) => {
                Analytics.logEvent("created_business", {
                    sender: "action",
                    user: user?.id,
                    business: res.data.body,
                    screen: `Onboard`,
                    purpose: "Created a new business",
                });
                setBusiness(res.data.body);
            })
            .catch((err) => {
                setErrors(err.response.data.errors);
            });
    }

    function uploadLogo() {
        setUploadingLogo(true);

        let fd = new FormData();
        if (Platform.OS == "web") {
            const file = new File([logo.blob], logo.name, {
                type: `image/${logo.type}`,
            });
            fd.append("logo", file);
        } else {
            fd.append("logo", { type: logo.type, uri: logo.uri, name: logo.name });
        }

        return api
            .business()
            .uploadLogo(business?.id, fd)
            .then((res) => {
                getAuthUser();
            })
            .catch((err) => {
                setUploadingLogo(false);
                setErrors(err.response.data.errors);
            });
    }

    function getAuthUser() {
        api.auth()
            .user()
            .then((res) => {
                setUser(res.data.body);
            })
            .then(() => {
                linkTo("/businessprofile/edit");
            });
    }

    return (
        <KeyboardAwareScrollView extraScrollHeight={30} enableOnAndroid>
            <SafeArea transparent />
            <StatusBar animated={true} style="dark" />
            <Container maxWidth={600}>
                <ActionBar back backOnPress={() => navigation.goBack()} />
                <VStack space={5} padding={4}>
                    <Center>
                        <Heading size="xl" mb={3}>
                            Let's get started!
                        </Heading>
                        <Text>Give us some info about your business.</Text>
                    </Center>
                    {business ? (
                        <VStack space={5} alignItems="center">
                            <Text>Upload a logo</Text>
                            <LogoUploader image={formData?.logo} onChange={(result) => setLogo(result)} />

                            {errors && "logo" in errors && <Text color={"error.500"}>{errors.logo}</Text>}

                            <Button onPress={() => uploadLogo()} isLoading={uploadingLogo} isLoadingText="Uploading">
                                Upload
                            </Button>
                        </VStack>
                    ) : (
                        <VStack space={5}>
                            <FormControl isRequired>
                                <FormControl.Label>Business name</FormControl.Label>
                                <Input
                                    key={4}
                                    value={formData.business_name}
                                    placeholder="e.g. Acme Burgers"
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            business_name: value,
                                        })
                                    }
                                />
                            </FormControl>

                            {errors && "business_name" in errors && (
                                <Text color={"error.500"}>{errors.business_name}</Text>
                            )}

                            <FormControl isRequired>
                                <FormControl.Label>Shop name</FormControl.Label>
                                <Input
                                    key={5}
                                    value={formData.shop_name}
                                    placeholder="e.g. New Farm"
                                    onChangeText={(value) =>
                                        setData({
                                            ...formData,
                                            shop_name: value,
                                        })
                                    }
                                ></Input>
                            </FormControl>

                            <HStack space={3}>
                                <AddressAutocomplete
                                    required
                                    onSelect={(addr) =>
                                        setData({
                                            ...formData,
                                            address: addr.place_name,
                                            lng: addr.center[0],
                                            lat: addr.center[1],
                                        })
                                    }
                                />
                            </HStack>

                            {errors && "address" in errors && <Text color={"error.500"}>{errors.address}</Text>}

                            {formData.lat !== "" && formData.lng !== "" && (
                                <Box>
                                    <Heading size="sm" my={4}>
                                        Matched location
                                    </Heading>

                                    {Platform.OS !== "web" && (
                                        <MapView
                                            region={{
                                                latitude: formData.lat,
                                                longitude: formData.lng,
                                                latitudeDelta: 0.03,
                                                longitudeDelta: 0.03,
                                            }}
                                            style={{
                                                width: "100%",
                                                height: 200,
                                                borderRadius: 20,
                                            }}
                                        >
                                            <Marker
                                                coordinate={{
                                                    latitude: formData.lat,
                                                    longitude: formData.lng,
                                                }}
                                            >
                                                <MaterialIcons name="my-location" color={markerColor} size={30} />
                                            </Marker>
                                        </MapView>
                                    )}
                                </Box>
                            )}

                            <Button onPress={() => onSubmit()}>Next</Button>
                        </VStack>
                    )}

                    <View style={{ height: 100 }}></View>
                </VStack>
            </Container>
        </KeyboardAwareScrollView>
    );
}
